<div id="registrationtoolselect">
  <dx-data-grid id="gridContainer"
                [dataSource]="dataSource"
                keyExpr="toolCode"
                [showBorders]="false"
                [showColumnHeaders]="kindOfWorkVisible"
                [wordWrapEnabled]="true"
                [hoverStateEnabled]="true"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-editing mode="cell"
                 [allowUpdating]="true">
    </dxo-editing>
    <dxo-sorting mode="single"></dxo-sorting>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxi-column dataField="selected" [allowEditing]="true" sortOrder="desc" [width]="60" caption="S"></dxi-column>
    <dxi-column dataField="toolDescription" [allowEditing]="false" caption="Mezzo"></dxi-column>
    <dxi-column dataField="kindOfWork" [visible]="kindOfWorkVisible" caption="Tipo di Lavoro">
      <dxo-lookup [dataSource]="getFilteredKindOfWorks"
                  valueExpr="kindOfWorkCode"
                  displayExpr="kindOfWorkDescription" 
      >
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="toolCode" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item name="searchPanel" location="before"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
</div>
