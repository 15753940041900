import { Injectable } from '@angular/core';
import { map, Observable, of, from } from 'rxjs';

import { liveQuery } from 'dexie';

import {Constructionsite} from '../models/constructionsite'
import {Cost} from '../models/cost'
import {HoursOption} from '../models/hours-option'
import {KindOfWork} from '../models/kind-of-work'
import {Tool} from '../models/tool'
import {WorkerCost} from '../models/worker-cost'
import {Worker} from '../models/worker'
import {db} from '../models/db'
import { Registration, ResourceRegistration, SelectedToolRegistration, SelectedWorkerRegistration, ToolRegistration, WorkerRegistration } from '../models/registration';
import { ConstructionPhase } from '../models/construction-phase';
import { Message } from '../models/message';

interface ConstructionSiteDTO {
  TenantID : number;
  ConstructionSiteCode: string;
  ConstructionSiteDescription: string;
  IsPhase: boolean;
  Kilometres: number;
  }

@Injectable({
  providedIn: 'root'
})
export class WorkerAndToolService {


  recreateDB() {
    return db.delete().then (()=>db.open());
  }

  getCosts(): Promise<Cost[]> {
    return db.cost.toArray();
  }

  getCostsObservable(): Observable<Cost[]> {
    return from(liveQuery(() => db.cost.toArray()));
  }

  getCostByKey(key: string): Promise<Cost | undefined> {
    return db.cost.get(key);
  }

  async syncCosts(nuoviCosti: Cost[]): Promise<void> {
    await db.cost.clear();
    await db.cost.bulkAdd(nuoviCosti);
  }

  getConstructionSites(): Promise<Constructionsite[]>{
    return db.constructionSite.toArray();
  }

  getConstructionSitesCount(): Promise<number> {
    return db.constructionSite.count();
  }

  getConstructionSitesObservable(): Observable<Constructionsite[]> {
    return from(liveQuery(() => db.constructionSite.toArray()));
  }

  async syncConstructionSites(nuovi: Constructionsite[]): Promise<void> {
    await db.constructionSite.clear();
    await db.constructionSite.bulkAdd(nuovi);
  }

  getConstructionSiteByKey(key: string): Promise<Constructionsite | undefined> {
    return db.constructionSite.get(key);
  }

  getConstructionPhases(constructionSite?: string): Promise<ConstructionPhase[]>{
    if (typeof constructionSite !== 'undefined') {
      return db.constructionPhase.where("constructionSiteCode").equalsIgnoreCase(constructionSite).toArray();
    }

    return db.constructionPhase.toArray();
  }

  getConstructionPhasesObservable(): Observable<ConstructionPhase[]> {
    return from(liveQuery(() => db.constructionPhase.toArray()));
  }

  async syncConstructionPhases(nuovi: ConstructionPhase[]): Promise<void> {
    await db.constructionPhase.clear();
    await db.constructionPhase.bulkAdd(nuovi);
  }

  getTools(): Promise<Tool[]>{
    return db.tool.toArray();
  }

  getToolsObservable(): Observable<Tool[]> {
    return from(liveQuery(() => db.tool.toArray()));
  }

  async syncTools(nuovi: Tool[]): Promise<void> {
    const count = await db.tool.count();
    if ( count > 0 )await db.tool.clear();
    await db.tool.bulkAdd(nuovi);
  }

  getKindOfWorks(): Promise<KindOfWork[]>{
    return  db.kindOfWork.toArray();
  }

  getKindOfWorksObservable(): Observable<KindOfWork[]> {
    return from(liveQuery(() => db.kindOfWork.toArray()));
  }

  async syncKindOfWorks(nuovi: KindOfWork[]): Promise<void> {
    await db.kindOfWork.clear();
    await db.kindOfWork.bulkAdd(nuovi);
  }

  getWorkers(): Promise<Worker[]>{
    return db.worker.toArray();
  }

  getCurrentWorker(): Promise<Worker | undefined> {
    let user = localStorage.getItem("user");
    if (user == null) user = "   ";
    const workerNumber = user.substring(3);
    return db.worker.get(workerNumber);

  }

  getCurrentWorkerObservable(): Observable<Worker | undefined> {
    let user = localStorage.getItem("user");
    if(user == null) user = "   ";
    const workerNumber = user.substring(3);
    return from(liveQuery(() => db.worker.get(workerNumber)));
  }

  getWorkersObservable(): Observable<Worker[]> {
    return from(liveQuery(() => db.worker.toArray()));
  }

  async syncWorkers(nuovi: Worker[]): Promise<void> {
    await db.worker.clear();
    await db.worker.bulkAdd(nuovi);
  }

  getHoursOption(): Promise<HoursOption | undefined> {
    return db.option.toCollection().first();
  }

  getHoursOptionObservable() : Observable<HoursOption | undefined>{
    return from ( liveQuery (()=>db.option.toCollection().first()) );
  }

  async syncHoursOption(nuovi: HoursOption): Promise<void> {
    await db.option.clear();
    await db.option.add(nuovi);
  }

  getMessages(): Promise<Message[]> {
    return db.message.toArray();
  }

  getLastMessage(): Promise<Message | undefined> {
    return db.message.orderBy(":id").last();
  } 

  getMessagesObservable(): Observable<Message[]> {
    return from(liveQuery(() => db.message.toArray()));
  }

  async syncMessages(nuovi: Message[]) {
    await db.message.bulkAdd(nuovi);
  }

  getReadedMessageObservable(): Observable<Message[]> {
    return from(liveQuery(() => db.readedMessage.toArray()));
  }

  deleteReaderMessage(messageId: number): Promise<void> {
    return db.readedMessage.delete(messageId);
  }

  async getMessage(messageId: number): Promise<Message | undefined> {
    const message = await db.message.get(messageId);

    if (message) {
      if (!message.flagRead) {
        message.flagRead = true;
        await db.message.update(message.messageId, { flagRead: true })
        const readed = await db.readedMessage.get(messageId);
        if (!readed) {
          await db.readedMessage.add(message);
        }
      }
    }

    return message;
  }

async getRegistrations() : Promise<Registration[]> {
  return await db.registration.toArray();
}

  async getRegistrationsByResource(): Promise<ResourceRegistration[]> {
    const registrations = await db.registration.toArray();

    const result: ResourceRegistration[] = [];

    registrations.forEach(r => {
      r.workerRegistrations.forEach(w => {
        result.push({
          dataRegistrazione: r.dataRegistrazione,
          descrizioneCantiere: r.descrizioneCantiere ?? "",
          id: r.id,
          ResourceDescription: w.workerDescription,
          oreLavorateManodopera: w.oreLavorate,
          oreLavorateMezzi: 0
        });

        r.toolRegistrations.forEach(t => {
          result.push({
            dataRegistrazione: r.dataRegistrazione,
            descrizioneCantiere: r.descrizioneCantiere ?? "",
            id: r.id,
            ResourceDescription: t.toolDescription,
            oreLavorateManodopera: 0,
            oreLavorateMezzi: t.oreLavorate
          });
        });
      });
    });

    return result;
  }

  async getRegistration(registrationId: number) : Promise<Registration | undefined> {
    return await db.registration.get(registrationId);
  }

  async addRegistration(registration: Registration): Promise<number> {
    registration.id = undefined;
    await this.normalizeRegistration(registration);
    return  await db.registration.add(registration)
  }

  async editRegistration(registration: Registration, registrationId: number): Promise<number> {
    await this.normalizeRegistration(registration);

    return await db.registration.put(registration, registrationId)
  }

  async deleteRegistration(registrationId: number): Promise<void> {
    return await db.registration.delete(registrationId);
  }

  async getTempRegistration(registrationId: number): Promise<Registration | undefined> {
    return await db.tempRegistration.get(registrationId);
  }

  async addTempRegistration(registration: Registration): Promise<number> {
    await this.normalizeRegistration(registration);
    await db.tempRegistration.clear();
    if (registration.id == null) registration.id = -1;
    return await db.tempRegistration.add(registration)
  }

  async editTempRegistration(registration: Registration, registrationId: number): Promise<number> {
    await this.normalizeRegistration(registration);
    return await db.tempRegistration.put(registration, registrationId)
  }

  private async normalizeRegistration(registration: Registration) {
    registration.dataRegistrazione = new Date(registration.dataRegistrazioneString);

    let user = localStorage.getItem("user");
    user ??= "   ";
    registration.registrationUser =  user.substring(3);

    registration.uuid = this.getApplicationToken();

    if (registration.cantiere) {
      const cantiere = await db.constructionSite.get(registration.cantiere);
      if (cantiere)
        registration.descrizioneCantiere = cantiere.constructionSiteDescription;
    }
    else {
      registration.descrizioneCantiere = "";
    }

    if (registration.fase) {
      const fase = await db.constructionPhase.get(registration.fase);
      if (fase)
        registration.descrizioneFase = fase.constructionPhaseDescription;
    }
    else {
      registration.descrizioneFase = "";
    }

    if (registration.costo) {
      const costo = await db.cost.get(registration.costo);
      if (costo)
        registration.descrizioneCosto = costo.costDescription;
    }
    else {
      registration.descrizioneCosto = "";
    }

    registration.totaleOreManodopera = registration.workerRegistrations.reduce((total, x) => total + (x.oreLavorate), 0)
    registration.totaleOreMezzi = registration.toolRegistrations.reduce((total, x) => total + (x.oreLavorate), 0)
  }

  async getSelectedWorkerRegistrations(workerRegistrations: WorkerRegistration[]) : Promise<SelectedWorkerRegistration[]> {
    const workers = await db.worker.toArray();
    const result: SelectedWorkerRegistration[] = [];

    for(let i=0;i<workers.length ;i++)
    {
      const selected = workerRegistrations.filter(f=>f.workerNumber === workers[i].workerNumber).length > 0;

      result.push({
        selected: selected,
        workerNumber: workers[i].workerNumber,
        workerDescription: workers[i].surname + ' ' + workers[i].name,

      });
    }

    return result;
  }

  async getSelectedToolRegistrations(registration: Registration) : Promise<SelectedToolRegistration[]> {
    const tools = await db.tool.toArray();
    const result: SelectedToolRegistration[] = [];

    for(let i=0;i<tools.length ;i++)
    {
      const selectedTools = registration.toolRegistrations.filter(f => f.toolCode === tools[i].toolCode);
      let selected = selectedTools.length > 0;
      let selectedKindOkWork = null;
      if (selected) {
        selectedKindOkWork = selectedTools[0].kindOfWork;
      }

      if (!selected) {
        selected = registration.workerRegistrations.filter(f => f.toolCode === tools[i].toolCode).length > 0;
      }

      result.push({
        selected: selected,
        toolCode: tools[i].toolCode,
        toolDescription: tools[i].toolCode + ' - ' + tools[i].toolDescription,
        toolCategory: tools[i].toolCategory,
        kindOfWork: selectedKindOkWork
      });
    }

    return result;
  }

  getNewRegistration(): Registration {
    let dataRegistrazione = new Date();
    const offset = dataRegistrazione.getTimezoneOffset();
    dataRegistrazione = new Date(dataRegistrazione.getTime() - (offset * 60 * 1000))
    
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    return {
      tenantId: +tenantId,
      dataRegistrazione: dataRegistrazione,
      dataRegistrazioneString: dataRegistrazione.toISOString().split('T')[0],
      cantiere: null,
      costo: null,
      fase: null,
      oreLavorate: null,
      inizio: null,
      fine: null,
      uuid: null,
      registrationUser: null,
      notes: "",
      workerRegistrations: [],
      toolRegistrations: [],
      workerKilometers: [],
      descrizioneCantiere: "",
      descrizioneCosto: "",
      descrizioneFase: "",
      totaleOreManodopera: 0,
      totaleOreMezzi: 0
    };
  }

  getApplicationToken(): string {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = this.getUniqueId(4);
      localStorage.setItem("uuid", uuid);
    }

    return uuid;
  }

  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  exportDatabase(): string {
    const allData =  db.transaction('r', db.tables, () => {
      return Promise.all(
        db.tables.map(table => table.toArray()
          .then(rows => ({ table: table.name, rows: rows }))));
    });

    const serialized = JSON.stringify(allData);

    return serialized;
  }
}
