<div id="registrationdriverskilometers">
  <dx-data-grid id="gridContainer"
                [dataSource]="registration!.workerKilometers"
                keyExpr="workerNumber"
                [showBorders]="true"
                [showColumnLines]="true"
                [showColumnHeaders]="false"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-editing mode="cell"
                 [allowUpdating]="true">
    </dxo-editing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxi-column dataField="workerNumber" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="workerDescription" sortOrder="asc" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="km" dataType="number" [width]="80" [editorOptions]="kmEditorOptions"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate class="informer">
           <span class="titoloGriglia">Chilometri percorsi?</span>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel" location="after"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
</div>
