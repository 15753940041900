import { AfterViewInit, Component, Input } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../../environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { ClockRegistrationElaboration, ClockRegistrationElaborationDetail } from '../../models/clock-registration-elaboration';
import DataSource from 'devextreme/data/data_source';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-clock-registration-validation-detail',
  templateUrl: './clock-registration-validation-detail.component.html',
  styleUrl: './clock-registration-validation-detail.component.scss',
  providers: [],
})
export class ClockRegistrationValidationDetailComponent implements AfterViewInit {
  @Input() key: number | undefined;
  @Input() rowData: ClockRegistrationElaboration | undefined;

  private unsubscribe: Subject<void> = new Subject<void>();

  backendURL: string = environment.ApiUrl + '/api';

  registrationDetail: ClockRegistrationElaborationDetail;
  tenantId: number;
  errorMessage = "";
  errorVisible = false;

  workersDataSource: any;
  toolsDataSource: any;
  attachmentsDataSource: any;

  lookupErrorData = [
    { id: 1, name: 'Ok' },
    { id: 2, name: 'Avviso' },
    { id: 3, name: 'Errore' },
  ];

  constructor(private restApi: RestApiService) {
    this.tenantId = restApi.readTenantId();

    this.registrationDetail = {
      tenantId: this.tenantId,
      userRegistrationID: 0,
      allegati: 0,
      mezzi: 0,
      mezziAssociatiAiDipendenti: 0,
      notes: null,
      workingPhaseCode: null,
      workingPhaseDescription: null
    };


  }

  ngAfterViewInit() {    
    this.workersDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'registrationId',
        loadParams: { userRegistrationId: this.key },
        loadUrl: `${this.backendURL}/ClockRegistrationElaborations/GetWorkers`,
        updateUrl: this.backendURL + '/ClockRegistrationElaborations/PutWorker',
        deleteUrl: this.backendURL + '/ClockRegistrationElaborations/DeleteWorker',
      }),

    });

    this.toolsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'registrationId',
        loadParams: { userRegistrationId: this.key },
        loadUrl: `${this.backendURL}/ClockRegistrationElaborations/GetTools`,
        updateUrl: this.backendURL + '/ClockRegistrationElaborations/PutTool',
        deleteUrl: this.backendURL + '/ClockRegistrationElaborations/DeleteTool',
      }),
    });

    this.attachmentsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'attachmentId',
        loadParams: { userRegistrationId: this.key },
        loadUrl: `${this.backendURL}/ClockRegistrationElaborations/GetAttachments`,
        deleteUrl: this.backendURL + '/ClockRegistrationElaborations/DeleteAttachment',
      }),
    });

    if (this.key) {
      this.restApi.getClockRegistrationElaborationDetail(this.key).pipe(takeUntil(this.unsubscribe)).subscribe((detail) => {
        this.registrationDetail = detail;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === "dataRow") {
      e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
        console.log(args.element);
        args.element.querySelector(".dx-texteditor-input").select();
      })
    }
  }

  onOpenClick = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data) {
      const fileName = environment.ApiUrl + "/" + e.row?.data.fileUrl;
      window.open(fileName, "_blank");
    }

    if (e.event)e.event.preventDefault();
  };
}
