import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent } from 'devextreme-angular';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';
import { confirm } from 'devextreme/ui/dialog';
import { HoursOption } from '../../shared/models/hours-option';
import { Clock } from '../../shared/models/clock';
import { Worker } from '../../shared/models/worker';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-clock-admin',
  templateUrl: './clock-admin.component.html',
  styleUrls: ['./clock-admin.component.scss']
})
export class ClockAdminComponent {
  @ViewChild(DxFormComponent, { static: false }) form!: DxFormComponent;

  private unsubscribe: Subject<void> = new Subject<void>();

  private direzioni = [
    { ID: 'E', Descrizione: 'Entrata' },
    { ID: 'U', Descrizione: 'Uscita' }
  ];

  constructionSitesStore: DataSource;
  constructionPhasesStore: DataSource;
  workersStore: DataSource;

  option: HoursOption | undefined;
  clock: Clock;
  clockId: number | null = null;

  notesEditorOptions: unknown;
  dataRegistrazioneEditorOptions: unknown;
  cantiereEditorOptions: unknown;
  faseEditorOptions: unknown;
  workerEditorOptions: unknown;
  directionEditorOptions: unknown;

  constructor(
    private service: WorkerAndToolService,
    private restApi: RestApiService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    this.clock = {
      clockId: 0,
      tenantID: +tenantId,
      deviceDate: new Date(),
      utcDeviceDate: new Date().toISOString(),
      manual: false,
      notes: "",
      constructionSiteCode: "",
      workerNumber: "",
      direction: ""
    };

    this.dataRegistrazioneEditorOptions = {
      displayFormat: "dd/MM/yyyy HH:mm",
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
      type: "datetime",
    };

    this.constructionSitesStore = this.restApi.getConstructionSitesDataSorce();

    this.cantiereEditorOptions = {
      dataSource: this.constructionSitesStore,
      searchEnabled: true,
      displayExpr: "constructionSiteDescription",
      valueExpr: "constructionSiteCode",
      showClearButton: true,
      searchExpr: "constructionSiteDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il cantiere",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.constructionPhasesStore = this.restApi.getConstructionPhasesDataSorce();

    this.faseEditorOptions = {
      dataSource: this.constructionPhasesStore,
      searchEnabled: true,
      displayExpr: "constructionSiteDescription",
      valueExpr: "constructionSiteCode",
      showClearButton: true,
      searchExpr: "constructionSiteDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare la fase",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.workersStore = this.restApi.getWorkersDataSorce();

    this.workerEditorOptions = {
      dataSource: this.workersStore,
      searchEnabled: true,
      displayExpr: "description",
      valueExpr: "workerNumber",
      showClearButton: true,
      searchExpr: "description",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il dipendente",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.directionEditorOptions = {
      dataSource: this.direzioni,
      displayExpr: "Descrizione",
      valueExpr: "ID",

    }
  }

  ngOnInit() {
    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => { this.option = val; });
    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.forwardClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Forward());
   
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(true);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(true);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId != null) this.clockId = +regId;

    if (this.clockId == null) {
      this.toolbar.TitleChanged("Nuova Timbratura");
    }
    else {
      this.toolbar.TitleChanged("Modifica Timbratura");

      this.restApi.getClock(this.clockId).pipe(takeUntil(this.unsubscribe)).subscribe((c) => {
        this.clock = c;        
      });
    }

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onFieldDataChanged(e: any) {
    if (e.dataField === "constructionSiteCode") {

      let contructionSiteFilter = e.value;
      while (contructionSiteFilter.endsWith("000")) {
        contructionSiteFilter = contructionSiteFilter.substring(0, contructionSiteFilter.length - 3);
      }

      const faseEditor = e.component.getEditor("workingPhaseCode");

      const dataSource = faseEditor.getDataSource();
      dataSource.filter(["ConstructionSiteCode", "startswith", contructionSiteFilter]);
      dataSource.reload();

      if (this.clock) {
        this.clock.workingPhaseCode = null;
      }
    }
  }

  Forward() {
    const result = this.form.instance.validate();

    if (result.isValid && this.clock != null) {
      this.clock.manual = true;

      if (this.clock.clockId && this.clock.clockId > 0) {
        this.restApi.putClock(this.clock).pipe(first()).subscribe({
          next: () => {
            this.router.navigate(['/clocks-admin']);
          }
        });  
      }
      else {
        this.restApi.postClock(this.clock).pipe(first()).subscribe({
          next: () => {
            this.router.navigate(['/clocks-admin']);
          }
        });  
        
      }
      
    }
  }

  Back() {
    const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Abbandono");
    result.then((dialogResult) => {
      if (dialogResult) {
        // Torno all'indice
        this.router.navigate(['/clocks-admin']);
      }
    });
  }
}
