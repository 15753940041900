export class MapTooltip {
  isShown = false;

  text = "";
}

export class MapMarker {
  constructor(latitude: number, longitude: number, text: string) {
    this.location = [latitude, longitude];
    this.tooltip = new MapTooltip();
    this.tooltip.text = text;
  }

  location: any;

  iconSrc: string | undefined;

  tooltip: MapTooltip = new MapTooltip();
}

export type APIKey = {
  bing?: string;

  google?: string;

  googleStatic?: string;
};

export interface MapInfo {
  latitudine?: number;
  longitudine?: number;
  precisione?: number;
  data?: Date;
}
