import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { Registration } from '../../shared/models/registration';
import { HoursOption } from 'src/app/shared/models/hours-option';

import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-registration-detail',
  templateUrl: './registration-detail.component.html',
  styleUrls: ['./registration-detail.component.scss']
})
export class RegistrationDetailComponent {
  registration!: Registration;
  option!: HoursOption;

  registrationId: number | null;

  optionSubscription: any;
  backSubscription: any;
  deleteSubscription: any;
  copySubscription: any;
  editSubscription: any;
  

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {


    this.registrationId = null;

    this.registration = {
      tenantId: 0,
      dataRegistrazione: new Date(),
      dataRegistrazioneString: "",
      cantiere: null,
      costo: null,
      fase: null,
      oreLavorate: null,
      inizio: null,
      fine: null,
      uuid: null,
      registrationUser: null,
      notes: "",
      descrizioneCantiere: "",
      descrizioneCosto: "",
      descrizioneFase: "",
      totaleOreManodopera: 0,
      totaleOreMezzi: 0,
      workerRegistrations: [],
      toolRegistrations: [],
      workerKilometers: []
    };

  }

  ngOnInit() {
    this.optionSubscription = this.service.getHoursOptionObservable().subscribe(val => { if (val) this.option = val; });
    this.backSubscription = this.toolbar.backClickedEvent.subscribe(() => this.Back());
    this.deleteSubscription = this.toolbar.deleteClickedEvent.subscribe(() => this.Delete());
    this.copySubscription = this.toolbar.copyClickedEvent.subscribe(() => this.Copy());
    this.editSubscription = this.toolbar.editClickedEvent.subscribe(() => this.Edit());


    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(true);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(true);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId != null) this.registrationId = +regId;

    if (this.registrationId != null){
      this.toolbar.TitleChanged("Dettaglio");

      if (this.registrationId > 0) {
        this.service.getRegistration(this.registrationId).then((reg) =>
        {
          if (reg) this.registration = reg;
        });
        this.toolbar.EditVisibleChanged(true);
        this.toolbar.DeleteVisibleChanged(true);
    }
      else
      {
        this.service.getTempRegistration(this.registrationId).then((reg) => { if (reg) this.registration = reg; });
        this.toolbar.EditVisibleChanged(false);
        this.toolbar.DeleteVisibleChanged(false);
     }
    }
  }

  ngOnDestroy() {
    this.optionSubscription.unsubscribe()
    this.backSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();

    this.copySubscription.unsubscribe();
    this.editSubscription.unsubscribe();

  }

  Delete(): void {
    const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Cancellazione");

    result.then((dialogResult) => {
      if (dialogResult && this.registrationId) {
        this.service.deleteRegistration(this.registrationId).then(()=>this.router.navigate(['/registrations']));
      }
    });

  }

  Edit(): void {
    this.service.addTempRegistration(this.registration).then(() =>
      this.router.navigate(['/registration', { id: this.registrationId }])
    );
  }

  Copy(): void {
    this.registration.id = -1;

    this.service.addTempRegistration(this.registration).then(() =>
      this.router.navigate(['/registration', { id: -1 }])
    );
  }

  Back(): void {
      this.router.navigate(['/registrations']);
  }
}
