<div id="registrationworkertools">
  <dx-data-grid id="gridContainer"
                [dataSource]="dataSource"
                keyExpr="toolCode"
                [showBorders]="false"
                [showColumnHeaders]="true"
                [wordWrapEnabled]="true"
                [hoverStateEnabled]="true"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-editing mode="cell"
                 [allowUpdating]="true">
    </dxo-editing>
    <dxo-sorting mode="single"></dxo-sorting>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxi-column dataField="workerDescription" [allowEditing]="false" caption="Dipendente"></dxi-column>
    <dxi-column dataField="toolCode" caption="Mezzo">
      <dxo-lookup [dataSource]="tools"
                  valueExpr="toolCode"
                  displayExpr="toolDescription" 
      >
      </dxo-lookup>
    </dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate class="informer">
          <span class="titoloGriglia">Mezzo guidato?</span>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel" location="after"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
</div>
