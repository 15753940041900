import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Registration } from 'src/app/shared/models/registration';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { HoursOption } from 'src/app/shared/models/hours-option';

import { alert } from 'devextreme/ui/dialog';
import { DxDataGridModule } from "devextreme-angular";
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-registration-tools-hour',
  templateUrl: './registration-tools-hour.component.html',
  styleUrls: ['./registration-tools-hour.component.scss']
})

export class RegistrationToolsHourComponent {
  registration!: Registration | undefined;
  option: HoursOption | any;

  optionSubscription: any;
  forwardSubscription: any;
  backSubscription: any;

  oreLavorateEditorOptions: object;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

      this.oreLavorateEditorOptions = {
          mode: 'number',
          showSpinButtons: false
      }

      this.registration = service.getNewRegistration();
    }

    ngOnInit() {
      this.optionSubscription = this.service.getHoursOptionObservable().subscribe(val=>this.option = val);

      this.backSubscription = this.toolbar.backClickedEvent.subscribe(()=>this.Back());
      this.forwardSubscription = this.toolbar.forwardClickedEvent.subscribe(()=>this.Forward());

      this.toolbar.BackVisibleChanged(true);
      this.toolbar.SaveVisibleChanged(false);
      this.toolbar.ForwardVisibleChanged(true);
      this.toolbar.CancelVisibleChanged(false);
      this.toolbar.PlusVisibleChanged(false);
      this.toolbar.EditVisibleChanged(false);
      this.toolbar.DeleteVisibleChanged(false);
      this.toolbar.CopyVisibleChanged(false);
      this.toolbar.MenuVisibleChanged(false);

      const regId = this.route.snapshot.paramMap.get('id');
      this.service.getTempRegistration(Number(regId)).then((reg)=>
      {
        this.registration = reg;
      });
    }

    ngOnDestroy() {
      this.optionSubscription.unsubscribe()
      this.backSubscription.unsubscribe();
      this.forwardSubscription.unsubscribe();
    }

    verificaOreNegative(e: { value: number; }) {
      return e.value >= 0;
    }

    verificaOreAZero(e: { value: number; }) {
      return e.value != 0;
    }

    verificaFrazioni(e: { value: number; }) {
      return (e.value * 40 % 10) == 0;
    }

    onEditorPreparing(e: EditorPreparingEvent) {
      if (e.dataField === "oreLavorate" && e.parentType === "dataRow") {
          e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
            console.log(args.element);
            args.element.querySelector(".dx-texteditor-input").select();
          })
      }
    }

    Forward()
    {
      if (this.registration)
      {
        const registrationId = this.registration.id ?? 0;

        if (registrationId > 0) {
          // Passo alla pagina successiva
          this.service.editRegistration(this.registration, registrationId)
            .then(() => {
              this.router.navigate(['/registrations'])
            });
        }
        else {
          this.service.addRegistration(this.registration)
            .then(() => {
              this.router.navigate(['/registrations'])
            })
        }
        
      }
      else
      {
        alert("Nessuna registrazione individuata","Errore");
      }
    }

    Back()
    {
      if (this.registration)
      {
        // Passo alla pagina successiva
          this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
            .then((regId)=>
                  {
            this.router.navigate(['/registration-tools-select', {id:this.registration?.id}]);
          })

        }
        else
        {
          alert("Nessuna registrazione individuata","Errore");
        }
  }

}
