import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { DxFormComponent } from 'devextreme-angular';

import { confirm } from 'devextreme/ui/dialog';
import { Clock } from '../../shared/models/clock';
import { APIKey, MapMarker } from '../../shared/models/map-marker';
import { Subject, takeUntil } from 'rxjs';
import { HoursOption } from '../../shared/models/hours-option';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';

@Component({
  selector: 'app-clock-detail',
  templateUrl: './clock-detail.component.html',
  styleUrls: ['./clock-detail.component.scss']
})
export class ClockDetailComponent {

  option: HoursOption | undefined;

  clock!: Clock;
  clockId: number | null;

  markers: MapMarker[] = [];

  apiKey: APIKey = {
    bing: "AuEvZEONNFI4llFDgLvMZK1B_lzJ-fJiPxxuR1ctYG8kMN_pAimVghJ9sGnTDz5L"
  };

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private service: WorkerAndToolService,
    private restApi: RestApiService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

    this.clockId = null;

    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    let dataRegistrazione = new Date();
    const offset = dataRegistrazione.getTimezoneOffset();
    dataRegistrazione = new Date(dataRegistrazione.getTime() - (offset * 60 * 1000))

    let user = localStorage.getItem("user");
    if (user == null) user = "   ";
    const workerNumber = user.substring(3);

    this.clock = {
      tenantID: +tenantId,
      deviceDate: dataRegistrazione,
      direction: "E",
      workerNumber: workerNumber
    }

  }

  ngOnInit() {
    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.deleteClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Delete());
    this.toolbar.editClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Edit());

    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(true);

    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val =>
    {
      this.option = val;

      if (this.option?.editClock) {
        this.toolbar.EditVisibleChanged(true);
        this.toolbar.CopyVisibleChanged(false);
      }
      else {
        this.toolbar.EditVisibleChanged(false);
        this.toolbar.CopyVisibleChanged(false);
      }

    });

   

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId != null) this.clockId = +regId;

    if (this.clockId != null) {
      this.toolbar.TitleChanged("Timbratura");

      if (this.clockId > 0) {
        this.restApi.getClock(this.clockId).pipe(takeUntil(this.unsubscribe)).subscribe(
          (clk) => {
            this.clock = clk;

            if (clk.latitudine && clk.longitudine) {
              const clkMarker = new MapMarker(clk.latitudine, clk.longitudine, 'Timbratura');
              this.markers.push(clkMarker);
            }

            if (clk.latitudineCantiere && clk.longitudineCantiere && clk.descrizioneCantiere) {
              const clkCantiereMarker = new MapMarker(clk.latitudineCantiere, clk.longitudineCantiere, clk.descrizioneCantiere);
              clkCantiereMarker.iconSrc = '/assets/construction-marker.png';
              this.markers.push(clkCantiereMarker);
            }
          }
        );        
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  Back(): void {
    this.router.navigate(['/clocks']);
  }

  Edit(): void {
    this.router.navigate(['/clock', { id: this.clockId }]);
  }

  Delete(): void {
    const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Cancellazione");

    result.then((dialogResult) => {
      if (dialogResult && this.clockId) {
        this.restApi.deleteClock(this.clockId).pipe(takeUntil(this.unsubscribe)).subscribe(() => this.router.navigate(['/clocks']));
      }
    });

  }
}
