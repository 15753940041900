import { Component, ViewChild } from '@angular/core';
import { Registration, SelectedWorkerRegistration } from 'src/app/shared/models/registration';
import { alert } from 'devextreme/ui/dialog';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { HoursOption } from 'src/app/shared/models/hours-option';

@Component({
  selector: 'app-registration-workers-select',
  templateUrl: './registration-workers-select.component.html',
  styleUrls: ['./registration-workers-select.component.scss']
})
export class RegistrationWorkersSelectComponent {

  registration!:  Registration | undefined;
  selectedRegistrations: SelectedWorkerRegistration[];
  option: HoursOption | any;

  forwardSubscription: any;
  backSubscription: any;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

      this.selectedRegistrations = [];

    }

    ngOnInit() {
      this.backSubscription = this.toolbar.backClickedEvent.subscribe(()=>this.Back());
      this.forwardSubscription = this.toolbar.forwardClickedEvent.subscribe(()=>this.Forward());

      this.toolbar.BackVisibleChanged(true);
      this.toolbar.SaveVisibleChanged(false);
      this.toolbar.ForwardVisibleChanged(true);
      this.toolbar.CancelVisibleChanged(false);
      this.toolbar.PlusVisibleChanged(false);
      this.toolbar.EditVisibleChanged(false);
      this.toolbar.DeleteVisibleChanged(false);
      this.toolbar.CopyVisibleChanged(false);
      this.toolbar.MenuVisibleChanged(false);

      const regId = this.route.snapshot.paramMap.get('id');
      this.service.getTempRegistration(Number(regId)).then((reg)=>
      {
        this.registration = reg;
        if (this.registration)
        {
            this.service.getSelectedWorkerRegistrations(this.registration.workerRegistrations).then((s)=>this.selectedRegistrations = s);
        }
      });


    }

    ngOnDestroy() {
      this.backSubscription.unsubscribe();
      this.forwardSubscription.unsubscribe();
    }

    Forward()
    {
      if (this.registration)
      {
        // verifico che vi sia almeno una riga inserita
        const currentSelectedRegistrations = this.selectedRegistrations.filter(s=>s.selected);
        if (currentSelectedRegistrations.length == 0)
        {
          alert("Nessun dipendente selezionato","Errore");
        }
        else
        {
          // elimino le righe delle registrazioni non presenti tra quelle selezionate

          this.registration.workerRegistrations = this.registration?.workerRegistrations.filter(r=> currentSelectedRegistrations.some( s=>s.workerNumber == r.workerNumber)) ?? [];

          // Aggiungo le righe delle registrazioni su quelle selezionate,
          // se esiste già non faccio nulla altrimenti aggiungo le ore
          const selectedMissingRegistrations = currentSelectedRegistrations.filter(s=> !this.registration?.workerRegistrations.some(f=>f.workerNumber == s.workerNumber));

          // Salvo la registrazione
          for(let i=0;i<selectedMissingRegistrations.length ;i++)
          {
            this.registration.workerRegistrations.push(
              {
                workerNumber: selectedMissingRegistrations[i].workerNumber,
                workerDescription: selectedMissingRegistrations[i].workerDescription,
                oreLavorate: this.registration.oreLavorate ?? 8,
                toolCode: null
              }
            );
          }

          // Passo alla pagina successiva
          this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId)=> this.router.navigate(['/registration-worker-hour',{id: regId}]))

        }
      }
      else
      {
        alert("Nessuna registrazione individuata","Errore");
      }
    }

    Back()
    {
      this.router.navigate(['/registration', {id:this.registration?.id}]);
    }
}
