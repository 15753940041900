import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';

import { ResourceRegistration } from 'src/app/shared/models/registration';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-registrations-by-resource',
  templateUrl: './registrations-by-resource.component.html',
  styleUrls: ['./registrations-by-resource.component.scss']
})
export class RegistrationsByResourceComponent {
  plusSubscription: any;

  registrations!: ResourceRegistration[];

  mezziVisible = true;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private router: Router) {

  }

  ngOnInit() {
    this.service.getRegistrationsByResource().then((reg) => this.registrations = reg);

    this.plusSubscription = this.toolbar.plusClickedEvent.subscribe(val => this.Plus());

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(true);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(false);

    this.toolbar.TitleChanged("Per Risorsa");

    this.service.getHoursOption().then(o => {
      if (o === undefined) this.mezziVisible = false;
      else {
        this.mezziVisible = o.toolRegistrationsEnabled;
        if (this.toolbar.syncTablesEnabled != o.syncTablesOnly) {
          this.toolbar.SyncTablesVisibleChanged(o.syncTablesOnly);
        }
      }
    })
  }

  customizeTotaleOre(cellInfo: { value: any, valueText: string, target: string; }) {
    if (cellInfo.value == "0") return "";
    return cellInfo.value;
  }

  customizeOre(cellInfo: { value: any, valueText: string, target: string; }) {
    if (cellInfo.value == 0) return "";
    return cellInfo.valueText;
  }
  onCellPrepared(e: CellPreparedEvent) {
    if (e.rowType === "group" && e.column.dataField === "oreLavorateManodopera") {
      e.cellElement.innerHTML = e.cellElement.innerHTML.replace("dx-datagrid-summary-item ", "dx-datagrid-summary-item totaleOreManodopera ");
    }
    if (e.rowType === "group" && e.column.dataField === "oreLavorateMezzi") {
      e.cellElement.innerHTML = e.cellElement.innerHTML.replace("dx-datagrid-summary-item ", "dx-datagrid-summary-item totaleOreMezzi ");
    }
  }

  onRowPrepared(e: RowPreparedEvent) {
    if (e.rowType === "group" && e.groupIndex === 0 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableRowElement = e.rowElement as HTMLTableRowElement
      if (tableRowElement) {
        tableRowElement.classList.add("group-data");

        tableRowElement.childNodes.forEach(f => {
          const html = f as HTMLTableCellElement;
          html.classList.add("group-data");
        });
      }

      e.rowElement.childNodes[0].childNodes.forEach(f => {
        const html = f as HTMLTableCellElement;
        html.classList.add("group-data");
      });
    }

  }

  ngOnDestroy() {
    if (this.plusSubscription) this.plusSubscription.unsubscribe()
  }

  Plus() {
    this.router.navigate(['/registration']);
  }

  onSelectionChanged(e: { selectedRowKeys: number[] }) {
    if (e.selectedRowKeys.length > 0) {
      this.router.navigate(['/registration-detail', { id: e.selectedRowKeys[0] }]);
    }
  }
}

