import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { from } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { AuthService, UserForAuthenticationDto } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { Router, Event, NavigationEnd } from '@angular/router';
import { ToolbarService } from '../../services/toolbar.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  backEnabled = false;
  cancelEnabled = false;
  forwardEnabled = false;
  saveEnabled = false;
  plusEnabled = false;
  editEnabled = false;
  copyEnabled = false;
  menuEnabled = false;
  deleteEnabled = false;
  synchTablesEnabled = false;
  menuResourceView: boolean | null = false;

  backButtonOptions = {
    icon: 'arrowleft',
    onClick: () => {
        this.toolbarService.BackClicked();
    }
  }

  cancelButtonOptions = {
    icon: 'clear',
    onClick: () => {
        this.toolbarService.BackClicked();
    }
  }

  forwardButtonOptions = {
    icon: 'arrowright',
    text: 'Avanti',
    onClick: () => {
        this.toolbarService.ForwardClicked();
    }
  }

  saveButtonOptions = {
    icon: 'save',
    onClick: () => {
        this.toolbarService.ForwardClicked();
    }
  }

  plusButtonOptions = {
    icon: 'add',
    onClick: () => {
        this.toolbarService.PlusClicked();
    }
  }

  editButtonOptions = {
    icon: 'edit',
    onClick: () => {
        this.toolbarService.EditClicked();
    }
  }

  copyButtonOptions = {
    icon: 'unselectall',
    onClick: () => {
        this.toolbarService.CopyClicked();
    }
  }

  deleteButtonOptions = {
    icon: 'trash',
    onClick: () => {
        this.toolbarService.DeleteClicked();
    }
  }

  user: UserForAuthenticationDto | null = null;

  userMenuItems = [{
    text: 'Cambia Visualizzazione',
    icon: 'user',
    onClick: () => {
      if (this.menuResourceView) this.router.navigate(['/registrations-by-resource']);
      else this.router.navigate(['/registrations']);
    }
  },
    {
      text: 'Sincronizza',
      icon: 'revert',
      onClick: () => {
        this.router.navigate(['/syncdata']);
      }
    },
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
  }];



  viewUserMenuItems = [{
    text: 'Cambia Visualizzazione',
    icon: 'user',
    onClick: () => {
      if (this.menuResourceView) this.router.navigate(['/registrations-by-resource']);
      else this.router.navigate(['/registrations']);
    }
  },
  {
    text: 'Sincronizza',
    icon: 'revert',
    onClick: () => {
      this.router.navigate(['/syncdata']);
    }
  },

  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
    }];

  noViewUserMenuItems = [
  {
    text: 'Sincronizza',
    icon: 'revert',
    onClick: () => {
      this.router.navigate(['/syncdata']);
    }
  },
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
    }];

  location = '';

  constructor(
      private authService: AuthService,
      private router: Router,
      private toolbarService: ToolbarService)
  {
    this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.location = event.url;
    });
  }

  titleSubscrition: any;
  backSubscrition: any;
  cancelSubscrition: any;
  forwardSubscrition: any;
  saveSubscrition: any;
  plusSubscription: any;
  crudSubscription : any;
  deleteSubscription: any;
  copySubscription: any;
  menuSubstription: any;
  menuResourceViewSubsription: any;
  syncTablesChangedSubstription: any;

  ngOnInit() {
    this.backEnabled = this.toolbarService.backEnabled;
    this.cancelEnabled = this.toolbarService.cancelEnabled;
    this.forwardEnabled = this.toolbarService.forwardEnabled;
    this.saveEnabled = this.toolbarService.saveEnabled;
    this.plusEnabled = this.toolbarService.plusEnabled;
    this.editEnabled = this.toolbarService.editEnabled;
    this.copyEnabled = this.toolbarService.copyEnabled;
    this.menuEnabled = this.toolbarService.menuEnabled;
    this.deleteEnabled = this.toolbarService.deleteEnabled;

    this.title = this.toolbarService.title;

    this.titleSubscrition = this.toolbarService.titleChangedEvent.subscribe(val => {
      this.title = val;
    });

    this.backSubscrition = this.toolbarService.backVisibleChangedEvent.subscribe(val=>this.backEnabled = val);

    this.cancelSubscrition = this.toolbarService.cancelVisibleChangedEvent.subscribe(val => this.cancelEnabled = val);

    this.forwardSubscrition = this.toolbarService.forwardVisibleChangedEvent.subscribe(val => this.forwardEnabled = val);

    this.saveSubscrition = this.toolbarService.saveVisibleChangedEvent.subscribe(val => this.saveEnabled = val);

    this.plusSubscription = this.toolbarService.plusVisibleChangedEvent.subscribe(val => this.plusEnabled = val);

    this.crudSubscription = this.toolbarService.editVisibleChangedEvent.subscribe(val => {
      this.editEnabled = val;
    });

    this.deleteSubscription = this.toolbarService.deleteVisibleChangedEvent.subscribe(val => {
      this.deleteEnabled = val;
    })

    this.copySubscription = this.toolbarService.copyVisibleChangedEvent.subscribe(val => {
      this.copyEnabled = val;
    });

    this.menuSubstription = this.toolbarService.menuVisibleChangedEvent.subscribe(val => this.menuEnabled = val);

    this.menuResourceViewSubsription = this.toolbarService.resourceMenuVisibleChangedEvent.subscribe(
      (val) => {
        this.menuResourceView = val;
        if (val != null) this.userMenuItems = this.viewUserMenuItems;
        else this.userMenuItems = this.noViewUserMenuItems;
      });

    this.syncTablesChangedSubstription = this.toolbarService.syncTablesChangedEvent.subscribe(val => {
      if (val) {
        if (this.noViewUserMenuItems.length == 2) {
          this.noViewUserMenuItems.splice(0, 0, {
            text: 'Sincronizza Tabelle',
            icon: 'parentfolder',
            onClick: () => {
              this.router.navigate(['/syncdata', { tables: 1 }]);
            }
          });

          this.noViewUserMenuItems[1].text = 'Sincronizza tutto';
        }

        if (this.viewUserMenuItems.length == 3) {
          this.viewUserMenuItems.splice(1, 0, {
            text: 'Sincronizza Tabelle',
            icon: 'parentfolder',
            onClick: () => {
              this.router.navigate(['/syncdata', { tables: 1 }]);
            }
          });

          this.viewUserMenuItems[2].text = 'Sincronizza tutto';
        }

      }
      else {
        if (this.noViewUserMenuItems.length == 3) {
          this.noViewUserMenuItems.splice(0, 1);

          this.noViewUserMenuItems[0].text = 'Sincronizza';
        }

        if (this.viewUserMenuItems.length == 4) {
          this.viewUserMenuItems.splice(1, 1);

          this.viewUserMenuItems[1].text = 'Sincronizza';
        }
      }
    });

  }

  ngOnDestroy() {
    this.titleSubscrition.unsubscribe();
    this.backSubscrition.unsubscribe();
    this.cancelSubscrition.unsubscribe();
    this.forwardSubscrition.unsubscribe();
    this.saveSubscrition.unsubscribe();
    this.plusSubscription.unsubscribe();
    this.copySubscription.unsubscribe();
    this.crudSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.menuSubstription.unsubscribe();
    this.menuResourceViewSubsription.unsubscribe();
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  onBackClick() {
    this.toolbarService.BackClicked();
  }

  onCancelClick() {
    this.toolbarService.BackClicked();
  }

  onForwardClick() {
    this.toolbarService.ForwardClicked();
  }

  onSaveClick() {
    this.toolbarService.ForwardClicked();
  }

  onPlusClick() {
    this.toolbarService.PlusClicked();
  }

  onCopyClick() {
    this.toolbarService.CopyClicked();
  }

  onEditClick() {
    this.toolbarService.EditClicked();
  }

  onDeleteClick() {
    this.toolbarService.DeleteClicked();
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
