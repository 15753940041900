import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Registration } from 'src/app/shared/models/registration';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { HoursOption } from 'src/app/shared/models/hours-option';

import { alert } from 'devextreme/ui/dialog';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-registration-workers-hour',
  templateUrl: './registration-workers-hour.component.html',
  styleUrls: ['./registration-workers-hour.component.scss']
})

export class RegistrationWorkersHourComponent {
  registration!: Registration | undefined;
  option: HoursOption | any;

  optionSubscription: any;
  forwardSubscription: any;
  backSubscription: any;

  oreLavorateEditorOptions: object;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

      this.oreLavorateEditorOptions = {
          mode: 'number',
          showSpinButtons: false
      }

      this.registration = service.getNewRegistration();
    }

    ngOnInit() {
      this.optionSubscription = this.service.getHoursOptionObservable().subscribe(val => {
        this.option = val;
        if (!this.option.toolRegistrationsEnabled) {
          this.toolbar.SaveVisibleChanged(true);
          this.toolbar.ForwardVisibleChanged(false);
        }
      });

      this.backSubscription = this.toolbar.backClickedEvent.subscribe(()=>this.Back());
      this.forwardSubscription = this.toolbar.forwardClickedEvent.subscribe(()=>this.Forward());

      this.toolbar.BackVisibleChanged(true);
      this.toolbar.SaveVisibleChanged(false);
      this.toolbar.ForwardVisibleChanged(true);
      this.toolbar.CancelVisibleChanged(false);
      this.toolbar.PlusVisibleChanged(false);
      this.toolbar.EditVisibleChanged(false);
      this.toolbar.DeleteVisibleChanged(false);
      this.toolbar.CopyVisibleChanged(false);
      this.toolbar.MenuVisibleChanged(false);

      const regId = this.route.snapshot.paramMap.get('id');
      this.service.getTempRegistration(Number(regId)).then((reg)=>
      {
        this.registration = reg;
      });
    }

    ngOnDestroy() {
      this.optionSubscription.unsubscribe()
      this.backSubscription.unsubscribe();
      this.forwardSubscription.unsubscribe();
    }


    verificaOreNegative(e: { value: number; }) {
      return e.value >= 0;
    }

    verificaOreAZero(e: { value: number; }) {
      return e.value != 0;
    }

    verificaOreOltreGiornata(e: { value: number; }) {
      return e.value < 24;
    }

    verificaFrazioni(e: { value: number; }) {
      return (e.value * 40 % 10) == 0;
    }

    onEditorPreparing(e: EditorPreparingEvent) {
      if (e.dataField === "oreLavorate" && e.parentType === "dataRow") {
          e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
            console.log(args.element);
            args.element.querySelector(".dx-texteditor-input").select();
          })
      }
    }

    Forward()
    {
      if (this.registration)
      {
        if (this.option.driversRegistrationsDisable) {
          if (this.option.toolRegistrationsEnabled) {
            this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
              .then((regId) => {

                this.router.navigate(['/registration-tools-select', { id: regId }])
              });
          }
          else {

            this.registration.id ??= 0;

            if (this.registration.id > 0) {
              this.service.editRegistration(this.registration, this.registration.id ?? 0)
                .then(() => {

                  this.router.navigate(['/registrations'])
                });
            }
            else {
              this.service.addRegistration(this.registration)
                .then(() => {

                  this.router.navigate(['/registrations'])
                });

            }
          }
        }
        else {
          if (this.option.driversRegistrationsTool) {
            this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
              .then((regId) => {
                this.router.navigate(['/registration-worker-tool', { id: regId }])
              });
          }
          else {
            this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
              .then((regId) => {
                this.router.navigate(['/registration-drivers-select', { id: regId }])
              });
          }
        }
      }
      else
      {
        alert("Nessuna registrazione individuata","Errore");
      }
    }

    Back()
    {
      this.router.navigate(['/registration-worker-select', {id:this.registration?.id}]);
    }
}
