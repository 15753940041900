<div class="content-block dx-card">
  <dx-data-grid [dataSource]="workersDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="registrationId"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="workerDescription"
                dataType="string"
                caption="Dipendente"
                [allowEditing]="false">
    </dxi-column>
    @if(registrationDetail.mezziAssociatiAiDipendenti > 0)
    {
    <dxi-column dataField="toolDescription"
                dataType="string"
                caption="Mezzo"
                [allowEditing]="false">
    </dxi-column>
    }
    <dxi-column dataField="workedHours"
                caption="Ore"
                [width]="100"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="clockHours"
                caption="Timbratura"
                [width]="100"
                [allowEditing]="false"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="errorLevel"
                caption="E"
                [allowEditing]="false"
                [allowFiltering]="false"
                [width]="60"
                cellTemplate="errorCellTemplate">
      <dxo-lookup [dataSource]="lookupErrorData"
                  valueExpr="id"
                  displayExpr="name">
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="approved"
                dataType="boolean"
                caption="A"
                [width]="80">
    </dxi-column>
    <dxo-master-detail [enabled]="true"
                       template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <app-clock-validation-detail [key]="masterDetailItem.data.clockId"
                                   [rowData]="masterDetailItem.data">
      </app-clock-validation-detail>
    </div>
    <div *dxTemplate="let d of 'errorCellTemplate'"
         [class]="d.value == 2 ? 'warn' : (d.value == 3 ? 'err' : 'ok')">
    </div>
  </dx-data-grid>
</div>

@if(registrationDetail.mezzi > 0){

<div class="content-block dx-card">
  <dx-data-grid [dataSource]="toolsDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="registrationId"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="toolCode"
                dataType="string"
                caption="Cod."
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="toolDescription"
                dataType="string"
                caption="Mezzo"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="workedHours"
                caption="Ore"
                [width]="100"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="approved"
                dataType="boolean"
                caption="A"
                [width]="80">
    </dxi-column>
  </dx-data-grid>
</div>

}

@if(registrationDetail.allegati > 0){

<div class="content-block dx-card">
  <dx-data-grid [dataSource]="attachmentsDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="attachmentId">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="false"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxi-column dataField="fileName"
                dataType="string"
                caption="Allegati"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="delete"></dxi-button>
      <dxi-button text="Apri"
                  icon="to"
                  hint="Apri"
                  [onClick]="onOpenClick">
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

}

@if(registrationDetail.notes){
<div class="content-block dx-card responsive-paddings">
  <p>
    {{registrationDetail.notes}}
  </p>
</div>
}
