import { Component } from '@angular/core';
import { Message } from '../../shared/models/message';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { Router } from '@angular/router';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  messages!: Message[];

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private router: Router) {

  }

  ngOnInit() {
    this.service.getMessages().then((mes) => this.messages = mes);

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(null);

    this.toolbar.TitleChanged("Messaggi");

    this.service.getHoursOption().then(o => {
      if (o !== undefined) {
        if (this.toolbar.syncTablesEnabled != o.syncTablesOnly) {
          this.toolbar.SyncTablesVisibleChanged(o.syncTablesOnly);
        }
      }
    })
  }

  onSelectionChanged(e: { selectedRowKeys: number[] }) {
    if (e.selectedRowKeys.length > 0) {
      this.router.navigate(['/message', { id: e.selectedRowKeys[0] }]);
    }
  }

  onCellPrepared(e: CellPreparedEvent) {
    if (e.rowType === "data") {
      e.cellElement.style.fontWeight = e.data.flagRead  ? "normal" : "bold";
    }
    
  }
}
