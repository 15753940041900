import { Component } from '@angular/core';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { CellPreparedEvent, EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/date_range_box';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-clock-validation',
  templateUrl: './clock-validation.component.html',
  styleUrls: ['./clock-validation.component.scss']
})
export class ClockValidationComponent {
  
  now: Date;
  startDate: Date;
  endDate: Date;
  minDate: Date; 
  maxDate: Date;

  backendURL: string = environment.ApiUrl + '/api';
  remoteDataSource: CustomStore;
  tenantId: number;

  lookupErrorData = [
    { id: 1, name: 'Ok' },
    { id: 2, name: 'Avviso' },
    { id: 3, name: 'Errore' },
  ];

  constructor(
    private restApi: RestApiService,
    private toolbar: ToolbarService) {

    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
    this.startDate = this.setToMonday(this.now);
    this.endDate = this.addDays(this.startDate, 7);
    this.minDate = this.addDays(this.startDate, - 120);
    this.maxDate = this.endDate;

    this.tenantId = restApi.readTenantId();

    this.remoteDataSource = this.createDataSource();
  }

  createDataSource(): CustomStore {
    return createStore({
      key: 'clockRegistrationId',
      loadUrl: this.backendURL + '/ClockElaborations/Get?TenantId=' + this.tenantId + '&StartDate=' + this.startDate.toISOString() + '&EndDate=' + this.endDate.toISOString(),
      updateUrl: this.backendURL + '/ClockElaborations/Put',
    });
  }

  ngOnInit() {
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(null);

    this.toolbar.TitleChanged("Validazione Timbrature");
  }

  onValueChanged(e: ValueChangedEvent) {
    this.startDate = e.value[0];
    this.endDate = e.value[1];

    this.remoteDataSource = this.createDataSource();
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField === "workedHours" && e.parentType === "dataRow") {
      e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
        console.log(args.element);
        args.element.querySelector(".dx-texteditor-input").select();
      })
    }
  }

  onCellPrepared(e: CellPreparedEvent) {
    if (e.rowType === "data" && e.column.dataField === "errorLevel") {
      //e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      // Tracks the `errorLevel` data field
      if (e.watch) {
        e.watch(function () {
          return e.data.errorLevel;
        }, function () {
          //e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
        })
      }
    }
  }

  setToMonday(date: Date): Date {
    const monday = new Date(date.getTime());

    const day = monday.getDay() || 7;
    if (day !== 1)
      monday.setHours(-24 * (day - 1));
    return monday;
}
  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  sinistraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, -7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };

  destraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, 7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };
}
