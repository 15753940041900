import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { LocationService } from '../../shared/services/location.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';
import { confirm } from 'devextreme/ui/dialog';
import { HoursOption } from '../../shared/models/hours-option';
import { Clock } from '../../shared/models/clock';
import { Worker } from '../../shared/models/worker';
import { DxFormComponent } from 'devextreme-angular';
import { first, Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent {
  @ViewChild(DxFormComponent, { static: false }) form!: DxFormComponent;

  constructionSitesStore: DataSource;
  constructionPhasesStore: DataSource;

  option: HoursOption | undefined;
  currentWorker: Worker | undefined;
  clock: Clock;
  clockId: number | null = null;

  private unsubscribe: Subject<void> = new Subject<void>();

  notesEditorOptions: unknown;
  dataRegistrazioneEditorOptions: unknown;
  cantiereEditorOptions: unknown;
  faseEditorOptions: unknown;

  constructor(
    private service: WorkerAndToolService,
    private restApi: RestApiService,
    private location: LocationService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    this.clock = {
      tenantID: +tenantId,
      deviceDate: new Date(),
      utcDeviceDate: new Date().toISOString(),
      manual: false,
      notes: "",
      constructionSiteCode: "",
      workerNumber: "",
      direction: ""
    };

    this.dataRegistrazioneEditorOptions = {
      displayFormat: "dd/MM/yyyy HH:mm",
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
      type: "datetime",
      disabled: true
    };

    this.constructionSitesStore = this.restApi.getConstructionSitesDataSorce();

    this.cantiereEditorOptions = {
      dataSource: this.constructionSitesStore,
      searchEnabled: true,
      displayExpr: "constructionSiteDescription",
      valueExpr: "constructionSiteCode",
      showClearButton: true,
      searchExpr: "constructionSiteDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il cantiere",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.constructionPhasesStore = this.restApi.getConstructionPhasesDataSorce();

    this.faseEditorOptions = {
      dataSource: this.constructionPhasesStore,
      searchEnabled: true,
      displayExpr: "constructionSiteDescription",
      valueExpr: "constructionSiteCode",
      showClearButton: true,
      searchExpr: "constructionSiteDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare la fase",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };
  }

  ngOnInit() {
    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => { this.option = val; });
    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.editClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Edit());
    this.service.getCurrentWorkerObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => { this.currentWorker = val; });

    this.toolbar.TitleChanged("Nuova Timbratura");

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(true);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

   

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId != null) this.clockId = +regId;

    if (this.clockId == null) {
      this.toolbar.TitleChanged("Nuova Timbratura");

      this.location.getCurrentLocation().then(position => {
        this.clock.latitudine = position.latitudine;
        this.clock.longitudine = position.longitudine;
        this.clock.deviceDate = new Date(),
          this.clock.utcDeviceDate = this.clock.deviceDate.toISOString(),
          this.clock.gpsDate = position.data;
        if (position.data) this.clock.utcGpsDate = position.data.toISOString();
        this.clock.precision = position.precisione;
      });
    }
    else {
      this.toolbar.TitleChanged("Modifica Timbratura");

      this.restApi.getClock(this.clockId).pipe(takeUntil(this.unsubscribe)).subscribe((c) => {
        this.clock = c;
        this.clock.manual = true;
        this.dataRegistrazioneEditorOptions = {
          displayFormat: "dd/MM/yyyy HH:mm",
          dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
          type: "datetime",          
        };

        this.toolbar.EditVisibleChanged(false);
      });
    }

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onFieldDataChanged(e: any) {
    if (e.dataField === "constructionSiteCode") {

      let contructionSiteFilter = e.value;
      while (contructionSiteFilter.endsWith("000")) {
        contructionSiteFilter = contructionSiteFilter.substring(0, contructionSiteFilter.length - 3);
      }

      const faseEditor = e.component.getEditor("workingPhaseCode");

      if (faseEditor) {
        const dataSource = faseEditor.getDataSource();
        dataSource.filter(["ConstructionSiteCode", "startswith", contructionSiteFilter]);
        dataSource.reload();
      }

      if (this.clock) {
        this.clock.workingPhaseCode = null;
      }
    }
  }

  entrataClick = () => {
    this.Timbra("E");
  };

  uscitaClick = () => {
    this.Timbra("U");
  };

  Timbra(direzione: string) {

    const result = this.form.instance.validate();

    if (result.isValid && this.currentWorker) {

      this.clock.direction = direzione;
      this.clock.workerNumber = this.currentWorker.workerNumber;

      this.restApi.postClock(this.clock).pipe(first()).subscribe({
        next: () => {
          this.router.navigate(['/clocks']);
        }
      });      
    }

   
  }

  Edit() {
    const result = confirm("<i>Sei sicuro?</i><br>La registrazione Verrà caricata come manuale", "Conferma Modifica");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.clock.manual = true;
        this.dataRegistrazioneEditorOptions = {
          displayFormat: "dd/MM/yyyy HH:mm",
          dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
          type: "datetime",
        };
      }
    });
  }

  Back() {
    const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Abbandono");
    result.then((dialogResult) => {
      if (dialogResult) {
        // Torno all'indice
        this.router.navigate(['/clocks']);
      }
    });
  }

}
