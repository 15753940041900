import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { Message } from '../../shared/models/message';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  message!: Message;

  messageId: number | null;

  backSubscription: any;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {


    this.messageId = null;

    this.message = {
      tenantId: 0,
      flagPrivate: false,
      flagRead: false,
      messageDate: new Date(),
      messageDescription: "",
      messageHtml: "",
      messageId: 0,
      messageTitle: "",
      workerNumber: ""
    };

  }

  ngOnInit() {
    this.backSubscription = this.toolbar.backClickedEvent.subscribe(() => this.Back());
    

    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId != null) this.messageId = +regId;

    if (this.messageId != null) {
      this.toolbar.TitleChanged("Messaggio");
      this.service.getMessage(this.messageId).then((msg) => { if (msg) this.message = msg; });
    }


  }

  ngOnDestroy() {
    this.backSubscription.unsubscribe();
  }

  Back(): void {
    this.router.navigate(['/messages']);
  }
}
