<div class="content-block dx-card responsive-paddings">
  <dx-form id="form"
    labelLocation="left"
    labelMode="floating"
    [formData]="registration"
    (onFieldDataChanged)="onFieldDataChanged($event)"
    [showValidationSummary]="true"
    validationGroup="registrationData">
    <dxo-col-count-by-screen [xs]="2"></dxo-col-count-by-screen>
    <dxi-item dataField="dataRegistrazioneString"
      editorType="dxDateBox"
      [isRequired]="true"
      [editorOptions]="dataRegistrazioneEditorOptions"
      [colSpan]="2">
      <dxo-label text="Data di registrazione"> </dxo-label>
      <dxi-validation-rule type="required"
        message="La data di registrazione è richiesta">
      </dxi-validation-rule>
      <!--<dxi-validation-rule
      type="range"
      [max]="maxDate"
      message="Non si può inserire una data più grande di quella di Oggi"
      >
    </dxi-validation-rule>-->
  </dxi-item>
  <dxi-item dataField="inizio"
    editorType="dxDateBox"
    [visible]="!option?.nascondiInizioFine"
    [editorOptions]="inizioFineEditorOptions"
    [colSpan]="1">
  </dxi-item>
  <dxi-item dataField="fine"
    editorType="dxDateBox"
    [visible]="!option?.nascondiInizioFine"
    [editorOptions]="inizioFineEditorOptions"
    [colSpan]="1">
  </dxi-item>
  <dxi-item dataField="oreLavorate"
    editorType="dxNumberBox"
    [isRequired]="!registration?.id || oreLavorateVisible"
    [visible]="!registration?.id  || oreLavorateVisible"
    [colSpan]="2">
  </dxi-item>
  <dxi-item dataField="cantiere"
    editorType="dxLookup"
    [editorOptions]="cantiereEditorOptions"
    [isRequired]="true"
    [colSpan]="2">
  </dxi-item>
  <dxi-item dataField="fase"
    editorType="dxLookup"
    [editorOptions]="faseEditorOptions"
    [isRequired]="(option?.workingPhase ?? 0 ) == 2"
    [colSpan]="2"
    [visible]="(option?.workingPhase ?? 0 ) >= 1">
  </dxi-item>
  <dxi-item dataField="costo"
    editorType="dxLookup"
    [editorOptions]="costoEditorOptions"
    [isRequired]="(option?.cost ?? 0) == 2"
    [colSpan]="2"
    [visible]="(option?.cost ?? 0 ) >= 1">
  </dxi-item>
  <dxi-item dataField="notes"
    editorType="dxTextArea"
    [caption]
    [editorOptions]="notesEditorOptions"
    [visible]="option?.notesEnabled"
    [colSpan]="2">
    <dxo-label text="Note"></dxo-label>
  </dxi-item>
</dx-form>
@if (option?.attachmentsEnabled) {
  <div>
    <dx-file-uploader #fileUploader
      [multiple]="true"
      accept="*"
      [(value)]="files"
      uploadMode="instantly"
      (onUploadError)="onUploadError($event)"
      (onValueChanged)="onFileUploaderValueChanged($event)">
    </dx-file-uploader>
  </div>
}
</div>
