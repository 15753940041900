<div id="registrations">
  <dx-data-grid id="gridContainer"
                [dataSource]="registrations"
                keyExpr="id"
                [showBorders]="false"
                [showColumnHeaders]="true"
                [hoverStateEnabled]="true"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellPrepared)="onCellPrepared($event)"
                (onRowPrepared)="onRowPrepared($event)"
                [selectedRowKeys]="[]">
    <dxi-column dataField="dataRegistrazione"
                caption="Data"
                [groupIndex]="0"
                groupCellTemplate="dataGroupCellTemplate"
                sortOrder="desc">
    </dxi-column>
    <dxi-column dataField="ResourceDescription"
                caption="Risorsa"
                [groupIndex]="1"
                groupCellTemplate="groupCellTemplate">
    </dxi-column>
    <dxi-column dataField="descrizioneCantiere"></dxi-column>
    <dxi-column dataField="oreLavorateManodopera" caption="" [width]="70" [customizeText]="customizeOre"></dxi-column>
    <dxi-column dataField="oreLavorateMezzi" caption="" [width]="70" [customizeText]="customizeOre" [visible]="mezziVisible"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-toolbar>
      <dxi-item name="searchPanel" location="before"></dxi-item>
      <dxi-item name="groupPanel" location="after"></dxi-item>
    </dxo-toolbar>
    <dxo-summary>
      <dxi-group-item column="oreLavorateManodopera"
                      summaryType="sum"
                      [alignByColumn]="true"
                      [showInGroupFooter]="false"
                      [customizeText]="customizeTotaleOre">
      </dxi-group-item>
      <dxi-group-item column="oreLavorateMezzi"
                      summaryType="sum"
                      [alignByColumn]="true"
                      [showInGroupFooter]="false"
                      [customizeText]="customizeTotaleOre">
      </dxi-group-item>

    </dxo-summary>

    <div *dxTemplate="let data of 'dataGroupCellTemplate'">
      <div>{{data.value|date:'d/MM/y '}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>{{data.value}}</div>
    </div>
  </dx-data-grid>
</div>
