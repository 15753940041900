import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { Subject, takeUntil } from 'rxjs';
import { HoursOption } from '../../shared/models/hours-option';
import { Registration } from '../../shared/models/registration';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';

import { alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-registration-drivers-kilometers',
  templateUrl: './registration-drivers-kilometers.component.html',
  styleUrls: ['./registration-drivers-kilometers.component.scss']
})
export class RegistrationDriversKilometersComponent {
  registration!: Registration | undefined;
  option: HoursOption | any;

  kmEditorOptions: object;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

    this.kmEditorOptions = {
      mode: 'number',
      showSpinButtons: false
    }

    this.registration = service.getNewRegistration();
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField === "km" && e.parentType === "dataRow") {
      e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
        console.log(args.element);
        args.element.querySelector(".dx-texteditor-input").select();
      })
    }
  }

  ngOnInit() {
    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => {
      this.option = val;
      if (!this.option.toolRegistrationsEnabled) {
        this.toolbar.SaveVisibleChanged(true);
        this.toolbar.ForwardVisibleChanged(false);
      }
    });

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.forwardClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Forward());

    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(true);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    this.service.getTempRegistration(Number(regId)).then((reg) => {
      this.registration = reg;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  Forward() {
    if (this.registration) {   
      if (this.option.toolRegistrationsEnabled) {
        this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
          .then((regId) => {

            this.router.navigate(['/registration-tools-select', { id: regId }])
          });
      }
      else {

        this.registration.id ??= 0;

        if (this.registration.id > 0) {
          this.service.editRegistration(this.registration, this.registration.id ?? 0)
            .then(() => {
              this.router.navigate(['/registrations'])
            });
        }
        else {
          this.service.addRegistration(this.registration)
            .then(() => {
              this.router.navigate(['/registrations'])
            });

        }
      }
    }
    else {
      alert("Nessuna registrazione individuata", "Errore");
    }
  }

  Back() {
    this.router.navigate(['/registration-drivers-select', { id: this.registration?.id }]);
  }
}
