<div class="dx-card responsive-paddings">
  <div class="button-toolbar">
    <dx-date-box [(value)]="inizio"
      type="date"
      label="Inizio"
      labelMode="floating"
      [width]="132"
      dateSerializationFormat="yyyy-MM-dd">

    </dx-date-box>
  </div>
  <div class="button-toolbar">
    <dx-date-box [(value)]="fine"
      type="date"
      label="Fine"
      labelMode="floating"
      [width]="132"
      dateSerializationFormat="yyyy-MM-dd">

    </dx-date-box>
  </div>
  <div class="button-toolbar">
    <dx-button stylingMode="contained"
      type="success"
      icon="search"
      (onClick)="searchClick()">
    </dx-button>
  </div>
</div>

<div id="registrations">
  <dx-data-grid id="gridContainer"
    [dataSource]="dataSource"
    keyExpr="id"
    [showBorders]="false"
    [showColumnHeaders]="false"
    [hoverStateEnabled]="true"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onRowPrepared)="onRowPrepared($event)"
    dataRowTemplate="dataRowTemplateName"
    [selectedRowKeys]="[]">
    <dxi-column dataField="dataRegistrazione"
      [groupIndex]="0"
      groupCellTemplate="dataGroupCellTemplate"
      sortOrder="desc"
      caption="Data" >
    </dxi-column>
    <dxi-column dataField="descrizioneCantiere"
      [groupIndex]="1"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>
    <dxi-column dataField="fase"></dxi-column>
    <dxi-column dataField="totaleOreManodopera" [width]="70"></dxi-column>
    <dxi-column dataField="totaleOreMezzi" [width]="70"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-summary>
      <dxi-group-item column="totaleOreManodopera"
        summaryType="sum"
        [alignByColumn]="true"
        [showInGroupFooter]="false"
        [customizeText]="customizeTotaleOre">
      </dxi-group-item>
      <dxi-group-item column="totaleOreMezzi"
        summaryType="sum"
        [alignByColumn]="true"
        [showInGroupFooter]="false"
        [customizeText]="customizeTotaleOre">
      </dxi-group-item>

    </dxo-summary>

    <div *dxTemplate="let data of 'dataGroupCellTemplate'">
      <div>{{data.value|date:'d/MM/y '}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>{{data.value}}</div>
    </div>

    <ng-container *dxTemplate="let registration of 'dataRowTemplateName'">
      @if (registration.data.fase) {
        <tr><td>&nbsp;</td><td>&nbsp;</td><td colspan="3">Fase : <b>{{registration.data.descrizioneFase}}</b></td></tr>
      }
      @if (registration.data.costo) {
        <tr><td>&nbsp;</td><td>&nbsp;</td><td colspan="3">Costo : <b>{{registration.data.descrizioneCosto}}</b></td></tr>
      }
      @if (registration.data.workerRegistrations.length) {
        <tr><td>&nbsp;</td><td>&nbsp;</td><td colspan="3"><b>Dipendenti</b></td></tr>
      }
      @for (item of registration.data.workerRegistrations; track item) {
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>{{item.workerDescription}}</td>
          <td style="text-align:right">{{item.oreLavorate}}</td>
          <td>&nbsp;</td>
        </tr>
      }
      @if (registration.data.toolRegistrations.length) {
        <tr><td>&nbsp;</td><td>&nbsp;</td><td colspan="3"><b>Mezzi</b></td></tr>
      }
      @for (item of registration.data.toolRegistrations; track item) {
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>{{item.toolDescription}}</td>
          <td>&nbsp;</td>
          <td style="text-align:right">{{item.oreLavorate}}</td>
        </tr>
      }
    </ng-container>
  </dx-data-grid>
</div>

