export const navigation = [
  {
    text: 'Registrazioni',
    path: '/registrations',
    icon: 'event'
  },
  {
    text: 'Cerca',
    path: '/search-registrations',
    icon: 'search'
  },
  {
    text: 'Timbrature',
    path: '/clocks',
    icon: 'clock'
  },
  {
    text: 'Gestione Timbrature',
    path: '/clocks-admin',
    icon: 'cellproperties'
  },
  {
    text: 'Valida Timbrature',
    path: '/clock-validation',
    icon: 'todo'
  },
  {
    text: 'Valida Registrazioni',
    path: '/clock-registration-validation',
    icon: 'importselected'
  },
  {
    text: 'Note spese',
    path: '/expenses',
    icon: 'money'
  },
  {
    text: 'Messaggi',
    path: '/messages',
    icon: 'email'
  },
];

export const navigationDemo = [
  {
    text: 'Registrazioni',
    path: '/registrations',
    icon: 'event'
  },
  {
    text: 'Cerca',
    path: '/search-registrations',
    icon: 'search'
  },  
  {
    text: 'Timbrature',
    path: '/clocks',
    icon: 'clock'
  },
  {
    text: 'Gestione Timbrature',
    path: '/clocks-admin',
    icon: 'cellproperties'
  },
  {
    text: 'Validazione Timbrature',
    path: '/clock-validation',
    icon: 'cellproperties'
  }, 
  {
    text: 'Note spese',
    path: '/expenses',
    icon: 'money'
  },
  {
    text: 'Messaggi',
    path: '/messages',
    icon: 'email'
  },
];
