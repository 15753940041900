<div id="registrationToolHours">
  <dx-data-grid id="gridContainer"
                [dataSource]="registration!.toolRegistrations"
                keyExpr="toolCode"
                [showBorders]="true"
                [showColumnLines]="true"
                [showColumnHeaders]="false"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-editing mode="cell"
                 [allowUpdating]="true">
    </dxo-editing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxi-column dataField="toolCode" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="toolDescription" sortOrder="asc" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="oreLavorate" dataType="number" [width]="80" [editorOptions]="oreLavorateEditorOptions">
      <dxi-validation-rule type="custom" [validationCallback]="verificaOreNegative" message="Non sono ammesse ore negative"></dxi-validation-rule>
      <dxi-validation-rule type="custom" [validationCallback]="verificaOreAZero" message="Non sono ammesse ore a zero"></dxi-validation-rule>
      <dxi-validation-rule type="custom" [validationCallback]="verificaFrazioni" message="Non sono ammesse frazioni di ore diverse da 0,25 o 0,5"></dxi-validation-rule>
    </dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item name="searchPanel" location="before"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
</div>
