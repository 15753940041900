import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private logger: NGXLogger) { }

  logError(message: string, stack: string) {

    
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";
    
    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);
    

    this.logger.error(message, stack, tenantId, workerNumber);
  }
}
