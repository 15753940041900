import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  @Output() backClickedEvent = new EventEmitter<void>();
  @Output() forwardClickedEvent = new EventEmitter<void>();
  @Output() plusClickedEvent = new EventEmitter<void>();
  @Output() copyClickedEvent = new EventEmitter<void>();
  @Output() editClickedEvent = new EventEmitter<void>();
  @Output() deleteClickedEvent = new EventEmitter<void>();

  @Output() backVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() cancelVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() forwardVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() saveVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() plusVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() editVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() deleteVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() copyVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() menuVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() syncTablesChangedEvent = new EventEmitter<boolean>();
  @Output() resourceMenuVisibleChangedEvent = new EventEmitter<boolean | null>();

  @Output() titleChangedEvent = new EventEmitter<string>();

  private _backEnabled = false;
  private _cancelEnabled = false;
  private _forwardEnabled = false;
  private _saveEnabled = false;
  private _plusEnabled = false;
  private _editEnabled = false;
  private _deleteEnabled = false;
  private _copyEnabled = false;
  private _menuEnabled = false;
  private _syncTables = false;

  private _title = "";
  private _resourceMenuEnabled: boolean | null = null;

  public get backEnabled() {
    return this._backEnabled;
  }

  public get cancelEnabled() {
    return this._cancelEnabled;
  }
  public get forwardEnabled() {
    return this._forwardEnabled;
  }

  public get saveEnabled() {
    return this._saveEnabled;
  }
  public get plusEnabled() {
    return this._plusEnabled;
  }
  public get editEnabled() {
    return this._editEnabled;
  }
  public get deleteEnabled() {
    return this._deleteEnabled;
  }
  public get copyEnabled() {
    return this._copyEnabled;
  }
  public get menuEnabled() {
    return this._menuEnabled;
  }
  public get syncTablesEnabled() {
    return this._syncTables;
  }

  public get resourceMenuEnabled() {
    return this._resourceMenuEnabled;
  }

  public get title() {
    return this._title;
  }

  BackClicked() {
    this.backClickedEvent.emit();
  }

  ForwardClicked() {
    this.forwardClickedEvent.emit();
  }

  PlusClicked() {
    this.plusClickedEvent.emit();
  }

  CopyClicked() {
    this.copyClickedEvent.emit();
  }

  EditClicked() {
    this.editClickedEvent.emit();
  }

  DeleteClicked() {
    this.deleteClickedEvent.emit();
  }

  BackVisibleChanged(visible: boolean) {
    this._backEnabled = visible;
    this.backVisibleChangedEvent.emit(visible);
  }

  CancelVisibleChanged(visible: boolean){
    this._cancelEnabled = visible;
    this.cancelVisibleChangedEvent.emit(visible);
  }

  ForwardVisibleChanged(visible: boolean){
    this._forwardEnabled = visible;
    this.forwardVisibleChangedEvent.emit(visible);
  }

  SaveVisibleChanged(visible: boolean){
    this._saveEnabled = visible;
    this.saveVisibleChangedEvent.emit(visible);
  }

  PlusVisibleChanged(visible: boolean){
    this._plusEnabled = visible;
    this.plusVisibleChangedEvent.emit(visible);
  }

  EditVisibleChanged(visible: boolean){
    this._editEnabled = visible;
    this.editVisibleChangedEvent.emit(visible);
  }

  DeleteVisibleChanged(visible: boolean) {
    this._deleteEnabled = visible;
    this.deleteVisibleChangedEvent.emit(visible);
  }

  CopyVisibleChanged(visible: boolean) {
    this._copyEnabled = visible;
    this.copyVisibleChangedEvent.emit(visible);
  }

  MenuVisibleChanged(visible: boolean) {
    this._menuEnabled = visible;
    this.menuVisibleChangedEvent.emit(visible);
  }

  ResourceMenuVisibleChanged(visible: boolean | null) {
    this._resourceMenuEnabled = visible;
    this.resourceMenuVisibleChangedEvent.emit(visible);
  }

  SyncTablesVisibleChanged(visible: boolean) {
    this._syncTables = visible;
    this.syncTablesChangedEvent.emit(visible);
  }

  TitleChanged(title: string) {
    this._title = title;
    this.titleChangedEvent.emit(title);
  }
}
