import { Component } from '@angular/core';

import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { Router } from '@angular/router';

import { lastValueFrom } from 'rxjs';

import { Registration } from 'src/app/shared/models/registration';

import { CellPreparedEvent, RowPreparedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import { RestApiService } from '../../shared/services/rest-api.service';
import { LoadOptions } from 'devextreme/data';

@Component({
  selector: 'app-search-registrations',
  templateUrl: './search-registrations.component.html',
  styleUrls: ['./search-registrations.component.scss']
})
export class SearchRegistrationsComponent {

  inizio: string | number | Date;
  fine: string | number | Date;

  dataSource: any = {};

  constructor(
    private service: WorkerAndToolService,
    private restApi: RestApiService,
    private toolbar: ToolbarService,
    private router: Router) {

    let dataRegistrazione = new Date();
    const offset = dataRegistrazione.getTimezoneOffset();
    dataRegistrazione = new Date(dataRegistrazione.getTime() - (offset * 60 * 1000))
    this.fine = dataRegistrazione.toISOString().split('T')[0];
    this.inizio = this.fine.substring(0, 8) + '01';

    this.dataSource = new CustomStore({
      key: 'id',
      load : () => {
        return lastValueFrom(this.restApi.getRegistrations(this.inizio.toString(), this.fine.toString()));
      },
      loadMode: 'raw'
    });
  }

  ngOnInit() {
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(null);

    this.toolbar.TitleChanged("Cerca");

    this.service.getHoursOption().then(o => {
      if (o !== undefined) {
        if (this.toolbar.syncTablesEnabled != o.syncTablesOnly) {
          this.toolbar.SyncTablesVisibleChanged(o.syncTablesOnly);
        }
      }
    })
  }

  customizeTotaleOre(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return cellInfo.value;
  }

  onCellPrepared(e: CellPreparedEvent) {
    //if (e.rowType === "group" && e.column.dataField === "totaleOreManodopera") {
    //  const tableCellElement = e.cellElement as HTMLTableCellElement;

    //  if (tableCellElement) {
    //    tableCellElement.classList.add("totaleOreManodopera")
    //  }
    //}
    //if (e.rowType === "group" && e.column.dataField === "totaleOreMezzi") {
    //  const tableCellElement = e.cellElement as HTMLTableCellElement;

    //  if (tableCellElement) {
    //    tableCellElement.classList.add("totaleOreMezzi")
    //  }
    //}
  }

  onRowPrepared(e: RowPreparedEvent) {
    if (e.rowType === "group" && e.groupIndex === 0 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      e.rowElement.childNodes[0].childNodes.forEach(f => {
        const html = f as HTMLTableCellElement;
        html.classList.add("group-data");
      });
    }

  }

  onSelectionChanged(e: SelectionChangedEvent) {
    if (e.selectedRowKeys.length > 0) {
      const registration = e.selectedRowsData[0] as Registration;
      this.service.addTempRegistration(registration).then(()=>

      this.router.navigate(['/registration-detail', { id: e.selectedRowKeys[0] }]));
    }
  }

  searchClick() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: () => {
        return lastValueFrom(this.restApi.getRegistrations(this.inizio.toString(), this.fine.toString()));
      },
      loadMode: 'raw'
    });
  }

}
