<div id="messages">
  <dx-data-grid id="gridContainer"
                [dataSource]="messages"
                keyExpr="messageId"
                [showBorders]="false"
                [showColumnHeaders]="false"
                [hoverStateEnabled]="true"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellPrepared)="onCellPrepared($event)"
                [selectedRowKeys]="[]">
    <dxi-column dataField="messageDate" [groupIndex]="0" sortOrder="desc" groupCellTemplate="groupCellTemplate"></dxi-column>
    <dxi-column dataField="messageTitle"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>{{data.value|date:'d/MM/y   H:mm '}}</div>
    </div>

  </dx-data-grid>
</div>
