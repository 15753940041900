import { Component, ViewChild } from '@angular/core';
import { Registration, SelectedToolRegistration } from 'src/app/shared/models/registration';
import { alert } from 'devextreme/ui/dialog';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { HoursOption } from 'src/app/shared/models/hours-option';
import { Worker } from 'src/app/shared/models/worker';
import { Subject, takeUntil } from 'rxjs';
import { KindOfWork } from '../../shared/models/kind-of-work';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-registration-tools-select',
  templateUrl: './registration-tools-select.component.html',
  styleUrls: ['./registration-tools-select.component.scss']
})
export class RegistrationToolsSelectComponent {
  registration!:  Registration | undefined;
  selectedRegistrations: SelectedToolRegistration[] = [];
  option: HoursOption | undefined;
  currentWorker: Worker | undefined;
  kindOfWorkVisible = false;
  kindOfWorks: KindOfWork[] = [];
  dataSource: any;

  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

      
    this.getFilteredKindOfWorks = this.getFilteredKindOfWorks.bind(this);
    }

  ngOnInit() {
      this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(()=>this.Back());
      this.toolbar.forwardClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Forward());

      this.service.getKindOfWorksObservable().pipe(takeUntil(this.unsubscribe)).subscribe((kow) => this.kindOfWorks = kow);

      this.service.getCurrentWorkerObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => {
        this.currentWorker = val;
        this.IsKindOfWorkVisible();
      });

      this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => {
        this.option = val;
        this.IsKindOfWorkVisible();
      });

      this.toolbar.BackVisibleChanged(true);
      this.toolbar.SaveVisibleChanged(false);
      this.toolbar.ForwardVisibleChanged(true);
      this.toolbar.CancelVisibleChanged(false);
      this.toolbar.PlusVisibleChanged(false);
      this.toolbar.EditVisibleChanged(false);
      this.toolbar.DeleteVisibleChanged(false);
      this.toolbar.CopyVisibleChanged(false);
      this.toolbar.MenuVisibleChanged(false);

      const regId = this.route.snapshot.paramMap.get('id');
      this.service.getTempRegistration(Number(regId)).then((reg)=>
      {
        this.registration = reg;
        if (this.registration)
        {
          this.service.getSelectedToolRegistrations(this.registration).then((s) => {
            this.selectedRegistrations = s;
            this.dataSource = {
              store: this.selectedRegistrations,
              key: 'toolCode'
            };
          });
        }
      });


    }
  
    ngOnDestroy() {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }

    private IsKindOfWorkVisible() {
      if (this.option && this.currentWorker) {

        this.kindOfWorkVisible = !this.currentWorker.siteManagerMode && (this.option.kindOfWork != 0);
      }
      else {
        this.kindOfWorkVisible = false;
      }
    }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField == "kindOfWork") {
      e.editorName = "dxLookup";  // "dxSelectBox" or "dxDropDownBox"  
      e.editorOptions.searchEnabled = true;
      e.editorOptions.searchExpr = "kindOfWorkDescription"
      e.editorOptions.searchMode = "contains"
      e.editorOptions.showClearButton = true;
      e.editorOptions.usePopover = false;
      e.editorOptions.wrapItemText = true;
      e.editorOptions.placeholder = "Selezionare il Tipo di Lavoro"
      e.editorOptions.dropDownOptions = {
        fullScreen: true,
          showTitle: false
      }
    }  

  }
  

  getFilteredKindOfWorks(options: { data: SelectedToolRegistration; }) {
      return {
        store: this.kindOfWorks,
        filter: options.data && options.data.toolCategory ? ['toolCategory', '=', options.data.toolCategory] : null,
      };
    }

    Forward()
    {
      this.grid.instance.saveEditData().then(() => this.saveRegistration());
    }



    private saveRegistration() {
        if (this.registration) {
            // verifico che vi sia almeno una riga inserita
            const currentSelectedRegistrations = this.selectedRegistrations.filter(s => s.selected);

            // elimino le righe delle registrazioni non presenti tra quelle selezionate
            this.registration.toolRegistrations = this.registration?.toolRegistrations.filter(r => currentSelectedRegistrations.some(s => s.toolCode == r.toolCode)) ?? [];

            if (currentSelectedRegistrations.length == 0) {
                this.registration.id ??= 0;
                if (this.registration.id > 0) {
                    // Passo alla pagina successiva
                    this.service.editRegistration(this.registration, this.registration.id ?? 0).then(() => this.router.navigate(['/registrations']));
                }
                else {
                    this.service.addRegistration(this.registration).then(() => this.router.navigate(['/registrations']));
                }
            }

            else {
                if (this.kindOfWorkVisible && this.option?.kindOfWork == 2 && currentSelectedRegistrations.filter(f => f.kindOfWork == null).length > 0) {
                  alert("Alcuni mezzi non hanno tipo di lavoro indicato", "Errore");
                  return;
                }

                // se il kind of work è visibile devo andare a settarlo nelle registrazioni già presenti
                if (this.kindOfWorkVisible) {
                    for (let i = 0; i < this.registration.toolRegistrations.length; i++) {
                        this.registration.toolRegistrations[i].kindOfWork = currentSelectedRegistrations.find(f => f.toolCode == this.registration?.toolRegistrations[i].toolCode)?.kindOfWork;
                    }
                }

                // Aggiungo le righe delle registrazioni su quelle selezionate,
                // se esiste già non faccio nulla altrimenti aggiungo le ore
                const selectedMissingRegistrations = currentSelectedRegistrations.filter(s => !this.registration?.toolRegistrations.some(f => f.toolCode == s.toolCode));

                // Salvo la registrazione
                for (let i = 0; i < selectedMissingRegistrations.length; i++) {
                    this.registration.toolRegistrations.push(
                        {
                            toolCode: selectedMissingRegistrations[i].toolCode,
                            toolDescription: selectedMissingRegistrations[i].toolDescription,
                            oreLavorate: this.registration.oreLavorate ?? 8,
                            kindOfWork: selectedMissingRegistrations[i].kindOfWork
                        }
                    );
                }

                // Passo alla pagina successiva
                this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId) => this.router.navigate(['/registration-tools-hour', { id: regId }]));

            }
        }

        else {
            alert("Nessuna registrazione individuata", "Errore");
        }
    }

    Back()
    {
      if (this.registration && this.currentWorker)
      {
        // Qui devo gestire il caso del worker singolo
        if (this.currentWorker.siteManagerMode) {
          // Passo alla pagina precedente
          this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId) => this.router.navigate(['/registration-worker-hour', { id: regId }]))
        }
        else {
          this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId) => this.router.navigate(['/registration', { id: regId, back: 1 }]))

        }
      }
      else
      {
        alert("Nessuna registrazione individuata","Errore");
      }
    }
}
