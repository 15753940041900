<h2 class="content-block">Sincronizzazione Tabelle</h2>
<div class="content-block dx-card responsive-paddings">
  <dx-progress-bar #progressBar
    id="progress-bar-status"
    width="90%"
    [class.complete]="progressBar.value == maxValue"
    [min]="0"
    [max]="maxValue"
    [statusFormat]="format"
    [value]="current">
  </dx-progress-bar>
</div>
@if (totalregistrations > 0) {
  <h2 class="content-block">Sincronizzazione Registrazioni</h2>
}
@if (totalregistrations > 0) {
  <div class="content-block dx-card responsive-paddings">
    <dx-progress-bar #progressBarRegistrazioni
      id="progress-bar-registrazioni"
      width="90%"
      [class.complete]="progressBarRegistrazioni.value == totalregistrations"
      [min]="0"
      [max]="totalregistrations"
      [statusFormat]="formatRegistrazione"
      [value]="currentRegistration">
    </dx-progress-bar>
  </div>
}
@if (registrationError) {
  <h2 class="content-block">Errori</h2>
}
@if (registrationError) {
  <div class="content-block dx-card responsive-paddings">
    <div>{{registrationError}}</div>
  </div>
}

