<div id="registrationworkerselect">
  <dx-data-grid
    id="gridContainer"
    [dataSource]="selectedRegistrations"
    keyExpr="workerNumber"
    [showBorders]="false"
    [showColumnHeaders]="false"
    [wordWrapEnabled]="true"
    [hoverStateEnabled]="true"
  >
  <dxo-editing
      mode="cell"
      [allowUpdating]="true"
    >
  </dxo-editing>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxi-column dataField="selected" sortOrder="desc" [allowEditing]="true" [width]="60"></dxi-column>
  <dxi-column dataField="workerDescription" sortOrder="asc" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="workerNumber" [visible]="false" [allowEditing]="false"></dxi-column>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <dxo-toolbar>
    <dxi-item name="searchPanel" location="before"></dxi-item>
  </dxo-toolbar>
  </dx-data-grid>
</div>
