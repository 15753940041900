<header>
  <dx-toolbar class="header-toolbar">
    @if (menuToggleEnabled) {
      <dxi-item
        location="before"
        widget="dxButton"
        cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
      </dxi-item>
    }
    @if (backEnabled) {
      <dxi-item
        location="before"
        widget="dxButton"
        [options]="backButtonOptions"
        >
      </dxi-item>
    }
    @if (cancelEnabled) {
      <dxi-item
        location="before"
        widget="dxButton"
        [options]="cancelButtonOptions"
        >
      </dxi-item>
    }
    @if (title) {
      <dxi-item
        location="before"
        cssClass="header-title"
        [text]="title">
      </dxi-item>
    }
    @if (saveEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="saveButtonOptions"
        >
      </dxi-item>
    }
    @if (forwardEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="forwardButtonOptions"
        >
      </dxi-item>
    }
    @if (plusEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="plusButtonOptions"
        >
      </dxi-item>
    }
    @if (editEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="editButtonOptions"
        >
      </dxi-item>
    }
    @if (copyEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="copyButtonOptions"
        >
      </dxi-item>
    }
    @if (deleteEnabled) {
      <dxi-item
        location="after"
        widget="dxButton"
        [options]="deleteButtonOptions"
        >
      </dxi-item>
    }
    @if (menuEnabled) {
      <dxi-item
        location="after"
        locateInMenu="auto"
        menuItemTemplate="menuItem">
        <div *dxTemplate="let data of 'item'">
          <dx-button
            class="user-button authorization"
            width="60px"
            height="100%"
            stylingMode="text">
            <div *dxTemplate="let data of 'content'">
              <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
            </div>
          </dx-button>
        </div>
      </dxi-item>
    }
    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>
  </dx-toolbar>
</header>
