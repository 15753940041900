import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { HoursOption } from '../../shared/models/hours-option';
import { Registration, SelectedWorkerRegistration } from '../../shared/models/registration';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';
import { alert } from 'devextreme/ui/dialog';
import { Constructionsite } from '../../shared/models/constructionsite';

@Component({
  selector: 'app-registration-drivers-select',
  templateUrl: './registration-drivers-select.component.html',
  styleUrls: ['./registration-drivers-select.component.scss']
})
export class RegistrationDriversSelectComponent {

  registration!: Registration | undefined;
  option: HoursOption | any;
  constructionSite: Constructionsite | undefined;
  
  selectedRegistrations: SelectedWorkerRegistration[];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {

    this.selectedRegistrations = [];
    this.registration = service.getNewRegistration();
  }

  ngOnInit() {
    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => this.option = val);

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.forwardClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Forward());

    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(true);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    this.service.getTempRegistration(Number(regId)).then((reg) => {
      this.registration = reg;

      if (this.registration && this.registration.cantiere) {
        this.service.getConstructionSiteByKey(this.registration.cantiere).then((cdc) => this.constructionSite = cdc);
      }

      if (this.registration) {
        for (let i = 0; i < this.registration.workerRegistrations.length; i++) {
          const selected = this.registration.workerKilometers.filter(f => this.registration && f.workerNumber === this.registration.workerRegistrations[i].workerNumber).length > 0;
          this.selectedRegistrations.push({
              selected: selected,
              workerNumber: this.registration.workerRegistrations[i].workerNumber,
              workerDescription: this.registration.workerRegistrations[i].workerDescription
            });
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  Forward() {
    if (this.registration) {
      // verifico che vi sia almeno una riga inserita
      const currentSelectedRegistrations = this.selectedRegistrations.filter(s => s.selected);
      if (currentSelectedRegistrations.length == 0) {

        this.registration.workerKilometers = []

        if (this.option.toolRegistrationsEnabled) {
          this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
            .then((regId) => {

              this.router.navigate(['/registration-tools-select', { id: regId }])
            });
        }
        else {

          this.registration.id ??= 0;

          if (this.registration.id > 0) {
            this.service.editRegistration(this.registration, this.registration.id ?? 0)
              .then(() => {
                this.router.navigate(['/registrations'])
              });
          }
          else {
            this.service.addRegistration(this.registration)
              .then(() => {
                this.router.navigate(['/registrations'])
              });

          }
        }
      }
      else {
        // elimino le righe delle registrazioni non presenti tra quelle selezionate
        let kilometers = 0;
        if (this.constructionSite && this.constructionSite.kilometres) kilometers = this.constructionSite.kilometres;
        if (currentSelectedRegistrations.length == 1) kilometers = 2 * kilometers;

        this.registration.workerKilometers = this.registration?.workerKilometers.filter(r => currentSelectedRegistrations.some(s => s.workerNumber == r.workerNumber)) ?? [];

        // Aggiungo le righe delle registrazioni su quelle selezionate,
        // se esiste già non faccio nulla altrimenti aggiungo le ore
        const selectedMissingRegistrations = currentSelectedRegistrations.filter(s => !this.registration?.workerKilometers.some(f => f.workerNumber == s.workerNumber));

        // Salvo la registrazione
        for (let i = 0; i < selectedMissingRegistrations.length; i++) {
          this.registration.workerKilometers.push(
            {
              workerNumber: selectedMissingRegistrations[i].workerNumber,
              workerDescription: selectedMissingRegistrations[i].workerDescription,
              km: kilometers
            }
          );
        }

        // Passo alla pagina successiva
        this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId) => this.router.navigate(['/registration-drivers-kilometers', { id: regId }]))

      }
    }
    else {
      alert("Nessuna registrazione individuata", "Errore");
    }
  }

  Back() {
    if (this.registration) {
      // Passo alla pagina successiva
      this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
        .then((regId) => {
          this.router.navigate(['/registration-worker-hour', { id: this.registration?.id }]);
        })

    }
    else {
      alert("Nessuna registrazione individuata", "Errore");
    }
  }
}
