// db.ts
import Dexie, { Table } from 'dexie';
import { Constructionsite } from './constructionsite';
import { ConstructionPhase } from './construction-phase';
import { Cost } from './cost';
import { HoursOption } from './hours-option';
import { KindOfWork } from './kind-of-work';
import { Tool } from './tool';
import { Message } from './message';
import { Worker } from './worker';
import { Registration } from './registration';

export class AppDB extends Dexie {
  constructionSite!: Table<Constructionsite, string>;
  constructionPhase!: Table<ConstructionPhase, string>;
  cost!: Table<Cost,string>;
  option!: Table<HoursOption,number>;
  kindOfWork!: Table<KindOfWork,string>;
  tool!: Table<Tool,string>;
  worker!: Table<Worker,string>;
  registration!: Table<Registration,number>;
  tempRegistration!: Table<Registration, number>;
  message!: Table<Message, number>;
  readedMessage!: Table<Message, number>;

  constructor() {
    super('hoursliveQuery');

    this.version(1).stores({
      constructionSite: 'constructionSiteCode',
      constructionPhase: 'constructionPhaseCode, constructionSiteCode',
      cost: 'costCode',
      option: 'tenantId',
      kindOfWork: 'kindOfWorkCode, toolCategory',
      tool: 'toolCode',
      worker: 'workerNumber',
      registration: '++id',
      tempRegistration: 'id',
      message: 'messageId',
      readedMessage: 'messageId'
    });

    //this.on('ready', () => this.populate());

    // Following operation will be queued until we're finished populating data:
    //this.constructionSite.each(function (obj) {
    //  // When we come here, data is fully populated and we can log all objects.
    //  console.log("Found object: " + JSON.stringify(obj));
    //}).then(function () {
    //  console.log("Finished.");
    //}).catch(function (error) {
    //  // In our each() callback above fails, OR db.open() fails due to any reason,
    //  // including our ajax call failed, this operation will fail and we will get
    //  // the error here!
    //  console.error(error.stack || error);
    //  // Note that we could also have catched it on db.open() but in this sample,
    //  // we show it here.
    //});
  }

}

export const db = new AppDB();
