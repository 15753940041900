import { Injectable } from '@angular/core';
import { MapInfo } from '../models/map-marker';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  getCurrentLocation(): Promise<MapInfo> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              console.log(
                'Latitude: ' +
                  position.coords.latitude +
                  'Longitude: ' +
                  position.coords.longitude
              );
              const mapInfo: MapInfo =
              {
                latitudine: position.coords.latitude,
                longitudine: position.coords.longitude,
                precisione: position.coords.accuracy,
                data: new Date(position.timestamp)
              }
              resolve(mapInfo);
            }
          },
          (error) => console.log(error),
          {
            enableHighAccuracy: true,
            timeout: 5000,
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}
