<dx-scroll-view height="100%" width="100%" class="with-footer single-card">
  <div class="content-block dx-card responsive-paddings">
    @if (!clock.manual) {
      <div>Data e Ora: <b>{{clock.gpsDate | date:'dd/MM/yyyy HH:mm:ss'}}</b></div>
    }
    @if (clock.manual) {
      <div>Data e Ora manuale: <b>{{clock.deviceDate | date:'dd/MM/yyyy HH:mm:ss'}}</b></div>
    }
    <div>Cantiere : <b>{{clock.descrizioneCantiere}}</b></div>
    @if (clock.descrizioneFase) {
      <div>Fase : <b>{{clock.descrizioneFase}}</b></div>
    }
    <div>Precisione : <b>{{clock.precision}}</b> Metri</div>
    <div>Distanza da cantiere : <b>{{clock.distanzaCantiereInMetri | number : '1.0-0' }}</b></div>
  </div>
  @if (!clock.manual) {
    <div>
      <dx-map #map
        center="40.7061, -73.9969"
        [zoom]="18"
        [height]="500"
        width="100%"
        provider="bing"
        [apiKey]="apiKey"
        [controls]="true"
        type="satellite"
        [markers]="markers">
      </dx-map>
    </div>
  }
</dx-scroll-view>
