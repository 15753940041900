import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WorkerAndToolService } from './worker-and-tool.service';

export interface UserForAuthenticationDto {
  Username: string;
  Password: string;
  Token: string;
}

export interface AuthResponseDto {
  isAuthSuccessful: boolean;
  errorMessage: string;
  fullName: string;
  token: string;
  clockActive: boolean;
  clockEdit: boolean;
  clockMaster: boolean;
  planningActive: boolean;
  planningMaster: boolean;
  expensesActive: boolean;
  expensesMaster: boolean;
  registrationActive: boolean;
}

const defaultPath = '/';


@Injectable()
export class AuthService {
  private _user: UserForAuthenticationDto | null = null;
  private _errorMessage: string | null = null;

  get loggedIn(): boolean {
    const token = localStorage.getItem("jwt");
    const user = localStorage.getItem("user");

    if (user) {
      this._user ??= {
        Username: user,
        Password: "",
        Token: ""
      };

    }

    if (this._user) {
      if (token && !this.jwtHelper.isTokenExpired(token)) return true;
    }

    return false;
  }

  get Auth(): AuthResponseDto {
    const loginOptionJson = localStorage.getItem("loginOptions") ?? "";

    const safeJsonParse = <T>(str: string) => {
      try {
        const jsonValue: T = JSON.parse(str);

        return jsonValue;
      } catch {
        return undefined;
      }
    };

    const loginOptions = safeJsonParse<AuthResponseDto>(loginOptionJson);

    if (loginOptions) return loginOptions;

    return {
      isAuthSuccessful: true,
      clockActive: false,
      clockEdit: false,
      clockMaster: false,
      expensesActive: false,
      expensesMaster: false,
      planningActive: false,
      planningMaster: false,
      registrationActive: true,
      errorMessage: "",
      fullName: "",
      token: localStorage.getItem("jwt") ?? ""
    }
  }

  get errorMessage(): string | null{
    return this._errorMessage;
  }

  private _lastAuthenticatedPath: string = defaultPath;

  get lastAuthenticatedPath() {
    if (!this._lastAuthenticatedPath) this._lastAuthenticatedPath = defaultPath;
    return this._lastAuthenticatedPath;
  }
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private workerAndToolService: WorkerAndToolService)
  {
  }

  logIn(email: string, password: string): Observable<AuthResponseDto> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    // aggiungo il token del telefono chiamante
    const token = this.workerAndToolService.getApplicationToken();

    const body: UserForAuthenticationDto = {
      Username: email,
      Password: password,
      Token: token
    }


    return this.http.post<AuthResponseDto>(environment.ApiUrl + '/Accounts/Login', body, httpOptions);     
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem("jwt");
    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private jwtHelper: JwtHelperService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;

    const isAuthForm = [
      'login-form'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    

    return isLoggedIn || isAuthForm;
  }
}
