import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { RestApiService } from '../../shared/services/rest-api.service';

import notify from 'devextreme/ui/notify';
import { first, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-syncdata',
  templateUrl: './syncdata.component.html',
  styleUrls: ['./syncdata.component.scss']
})
export class SyncdataComponent {

  current = 0;

  maxValue = 8;
  lastMessage = 0;

  inProgress = false;

  totalregistrations = 0;
  currentRegistration = 0;
  registrationError: string | null = null;

  private unsubscribe: Subject<void> = new Subject<void>();


  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private restApi: RestApiService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    this.syncData();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  format(ratio: number) {
    return `Sincronizzazione: ${ratio * 100}%`;
  }

  formatRegistrazione(ratio: number) {
    return `Registrazioni: ${ratio * 100}%`;
  }

  syncData() {
    this.restApi.getConstructionSites().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncConstructionSites(s).then(() => { this.current++; notify('Cantieri Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getConstructionPhases().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncConstructionPhases(s).then(() => { this.current++; notify('Cantieri Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getCosts().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncCosts(s).then(() => { this.current++; notify('Costi Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getWorkers().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncWorkers(s).then(() => { this.current++; notify('Dipendenti Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getTools().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncTools(s).then(() => { this.current++; notify('Mezzi Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getKindOfWorks().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncKindOfWorks(s).then(() => { this.current++; notify('Tipi lavoro Sincronizzati', 'info', 1000); this.syncCompleted(); }));

    this.restApi.getOptions().pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncHoursOption(s).then(() => { this.current++; notify('Opzioni Sincronizzate', 'info', 1000); this.syncCompleted(); }));

    this.service.getLastMessage().then(
      (m) => {
        let messageId = 0;
        if (m) messageId = m.messageId;

        this.restApi.getMessages(messageId).pipe(takeUntil(this.unsubscribe)).subscribe((s) => this.service.syncMessages(s).then(() => { this.current++; notify('Messaggi Sincronizzati', 'info', 1000); this.syncCompleted(); }));

      }
    )

    const tables = this.route.snapshot.paramMap.get('tables');

    if (tables === null || tables === undefined) {
      this.service.getRegistrations()
        .then(
          registrations => {
            this.totalregistrations = registrations.length;

            registrations.forEach(r => this.restApi.postRegistration(r).pipe(first()).subscribe({
              next: () => {
                this.currentRegistration++;
                if (r.id) this.service.deleteRegistration(r.id);
                this.syncCompleted();
              },
              error: (err: HttpErrorResponse) => {
                this.currentRegistration++;
                this.registrationError ??= "";
                this.registrationError = this.registrationError + '\r\n' + this.registrationError + err.error.errorMessage;
                this.syncCompleted();
              }
            }))
          });
    }
  }

  syncCompleted() {
    if (this.totalregistrations == this.currentRegistration && this.maxValue == this.current && !this.registrationError) {
      this.router.navigate(['/registrations']);
    }
  }
}
